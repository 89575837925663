import { useState } from 'react'
import { basicProps, orderType } from '../../../utils/interfaces';
import ShowOrderStaus from './ShowOrderStaus';
import AdminMessage from './AdminMessage';
import { 
    Alert, Avatar, Box, Chip, List, ListItem, ListItemAvatar, ListItemText, 
    Stack, Tooltip, Typography, TableContainer, Table, Paper, TableBody, TableRow, AlertTitle, Button 
} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Page404 from '../../../components/Page404';
import { copyClipboard, isPC, readabilityNumber } from '../../../functions/basicFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import InfoPopup from '../../../components/InfoPopup';
import { Link, useLocation } from 'react-router-dom';
import MarkAsPaidBtn from './MarkAsPaidBtn';
import ListInstructions from './ListInstructions';
import QrCodeDisplay from '../../../components/QrCodeDisplay';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


interface myProps extends basicProps {
    orderData: orderType;
    confirmPayment: () => void,
    cancelOrder: () => void
}

export default function SwapOrder(props: myProps) {

    const location = useLocation();

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const receivedCurrency = orderData.cryptoCurrency;
    const receivedNetwork = orderData.network;
    const networkFee = orderBasicInfos.networkFee;
    const serviceFees = orderBasicInfos.serviceFees;

    const sendInfos = orderData.sendInfos;
    const sendCurrency = sendInfos.sendCurrency;
    const sendNetwork = sendInfos.sendNetwork;
    const sendQte = sendInfos.sendQte;

    const [addressCopied, setAddressCopied] = useState(false);
    const [memoCopied, setMemoCopied] = useState(false);

    let receivedQte = networkFee ? (parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(networkFee + '')) : orderBasicInfos.cryptoQte;
    receivedQte = serviceFees ? (parseFloat(receivedQte as string) - parseFloat(serviceFees + '')) : receivedQte;
    receivedQte = readabilityNumber(receivedQte);
    
    let qteToSend = parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(serviceFees + '');

    if(!sendInfos.sendCurrency) return (
        <Page404 
            basicDatas={basicDatas}
            title='Ordre indisponible'
            message="L'ordre est indisponible ou il a été retiré de façon complète de CRYPTO-FIAT" 
        />
    )


    if((orderBasicInfos.status !== 'waiting_payment') && (orderBasicInfos.status !== 'marked_paid'))
    {
        return (
            <>
                <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
                <AdminMessage order={orderData}/>
            </>
        )
    }

    return (
        <Box>
            
            <Stack
                direction={'row'}
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    mb: 7,
                    mt: 3
                }}
            >


                <List 
                    sx={{
                        bgcolor: 'ButtonFace',
                        borderRadius: 2
                    }}
                >
                    <ListItem alignItems="flex-start">

                        <ListItemAvatar>
                            <Avatar 
                                alt={sendCurrency.symbol} 
                                src={sendCurrency.logo} 
                                sx={{
                                    width: isPC ? 35 : 30,
                                    height: isPC ? 35 : 30
                                }}
                            />
                        </ListItemAvatar>

                        <ListItemText
                            primary={<b>{sendCurrency.symbol}</b>}
                            secondary={
                                <>
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        color="text.primary"
                                    >
                                        { sendNetwork.symbol }
                                    </Typography>

                                    <Typography>
                                        { readabilityNumber(sendQte) }
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>

                </List>

                <ArrowRightAltIcon color='primary' sx={{fontSize: 50, opacity: 0.7}} />

                <List 
                    sx={{
                        bgcolor: 'ButtonFace',
                        borderRadius: 2
                    }}
                >
                    <ListItem alignItems="flex-start">

                        <ListItemAvatar>
                            <Avatar 
                                alt={receivedCurrency.symbol} 
                                src={receivedCurrency.logo} 
                                sx={{
                                    width: isPC ? 35 : 30,
                                    height: isPC ? 35 : 30
                                }}
                            />
                        </ListItemAvatar>

                        <ListItemText
                            primary={<b>{receivedCurrency.symbol}</b>}
                            secondary={
                                <>
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        color="text.primary"
                                    >
                                        { receivedNetwork.symbol }
                                    </Typography>

                                    <Typography>
                                        { readabilityNumber(orderBasicInfos.cryptoQte) }
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>

                </List>

            </Stack>


            <Typography variant='h6' sx={{mb: 3, textAlign: 'left', fontWeight: 'bold'}}>
                Effectuer le paiement
            </Typography>

            <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
            
            { orderBasicInfos.status !== 'waiting_payment' && <br /> }

            {
                ((orderBasicInfos.status === 'waiting_payment') && (!location.search.match('recap'))) &&

                <Alert severity="warning" sx={{marginTop:'30px', marginBottom:'20px', textAlign:'left'}}>
                    
                    <AlertTitle style={{fontWeight:'bold'}}>Geste important</AlertTitle>

                    Votre avez placé votre ordre avec succès. Mais avant de procéder au paiement, nous vous invitons à jetter un dernier coup d'oeil sur le 
                    <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link text-primary"> récapitulatif</Link>.

                    <br />

                    <div style={{textAlign:'right', marginTop:'10px'}}>
                        <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link">
                            <Button variant='contained' color='inherit'>
                                Voir le récapitulatif 
                            </Button> 
                        </Link>
                    </div>

                </Alert>
            }

            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'20px'}}>
                <QrCodeDisplay
                    text={sendNetwork.address}
                    sx={{
                        width: 150,
                        height: 150,
                        borderRadius: 1,
                        boxShadow: 3
                    }}
                />
            </div>

            <Tooltip title="Cliquez pour copier l'adresse">
                <Chip
                        label={
                            <>
                                { 
                                    ((sendNetwork.memoRequiredForBuy == 1) && sendNetwork.memo) &&
                                
                                    <Typography variant='caption'>
                                        Adresse
                                    </Typography> 
                                }

                                { ' ' + sendNetwork.address }
                            </>
                        }
                        avatar={
                            !addressCopied 
                            ? <ContentCopyIcon sx={{fontSize:'small'}} />
                            : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                        }
                        variant="outlined"
                        sx={{fontWeight:'bold', fontSize:'15px', marginTop:'10px', cursor:'pointer'}}
                        onClick={() => { copyClipboard(sendNetwork.address, "Adresse copiée !"); setAddressCopied(true)}}
                    />

            </Tooltip>

            {
                ((sendNetwork.memoRequiredForBuy == 1) && sendNetwork.memo) && 

                <Typography sx={{
                    mt: 1
                }}>

                    <Tooltip title="Cliquez pour copier l'adresse">
                        <Chip
                                label={
                                    <>
                                        <Typography variant='caption'>
                                            Memo
                                        </Typography> 
                                        { ' ' + sendNetwork.memo }
                                    </>
                                }
                                avatar={
                                    !memoCopied 
                                    ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                    : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                                }
                                variant="outlined"
                                sx={{fontWeight:'bold', fontSize:'15px', marginTop:'10px', cursor:'pointer'}}
                                onClick={() => {copyClipboard(sendNetwork.memo ? sendNetwork.memo : '', "Le memo a été copiée dans le presse-paier."); setMemoCopied(true)}}
                            />

                    </Tooltip>

                    <Alert severity='warning' sx={{mt: 1, width: 'auto'}}>
                        L'adresse et le memo sont tous deux nécessaires, sinon vous perdrez vos cryptos.
                    </Alert>

                </Typography>

            }

            <div style={{fontWeight:'bold', marginTop:'15px', fontSize:'25px'}}>
                { readabilityNumber(sendQte) } <br />
                { sendCurrency.symbol + '_' + sendNetwork.symbol}
                <Avatar src={sendCurrency.logo}
                        sx={{
                            display: 'inline-block',
                            ml: 1,
                            width: '23px',
                            height: '23px'
                        }} />
            </div>

            <TableContainer component={Paper}  sx={{mt:5}}>
                <Table aria-label="customized table">

                    <TableBody>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Quantité crypto
                            </StyledTableCell>
                            <StyledTableCell 
                                align="left"
                                sx={{cursor: 'pointer'}}
                                onClick={() => copyClipboard(qteToSend + '', "Montant copié !")}
                                >
                                <b>{ readabilityNumber(orderBasicInfos.cryptoQte) + " " + receivedCurrency.symbol}</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Frais réseau

                                <InfoPopup basicDatas={basicDatas}
                                            title="Pourquoi les frais de réseau ?"
                                            text={
                                                <div>
                                                    Ces frais sont dus au fait que les frais de gas sur le réseau { receivedNetwork.symbol + " (" + receivedNetwork.name + ")" } sont élévés. Alors l'utilisateur paye une partie et CRYPTO-FIAT prend en charge le reste.

                                                    <br /> <br />

                                                    <div style={{textAlign: 'right'}} className='small'>
                                                        <a href="https://academy.binance.com/fr/articles/what-are-blockchain-transaction-fees" target={'_blank'}>
                                                            C'est quoi les frais de gas ?
                                                        </a>
                                                    </div>
                                                </div>
                                            }>
                                    <span   className='small text-primary'
                                            style={{
                                                marginLeft: '20px',
                                                fontStyle: 'oblique',
                                                cursor: 'pointer',
                                                float: 'right'
                                            }}> 
                                                Pourquoi ces frais ?
                                    </span>
                                </InfoPopup>

                            </StyledTableCell>

                            <StyledTableCell align="left">
                                <b>{ orderBasicInfos.networkFee ? readabilityNumber(orderBasicInfos?.networkFee) + " " + receivedCurrency.symbol :  '0 ' + receivedCurrency.symbol}</b>
                            </StyledTableCell>

                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                
                                Frais de service

                                <InfoPopup basicDatas={basicDatas}
                                            title="Pourquoi les frais de service ?"
                                            text={
                                                <div>
                                                    Ces frais permettent à { basicDatas.appName } de maintenir la plateforme afin de continuer à vous servir. <br />
                                                    Ces frais aident également à atténuer le risque lié à la volatilité des cryptomonnaies.
                                                </div>
                                            }>
                                    <span   className='small text-primary'
                                            style={{
                                                marginLeft: '20px',
                                                fontStyle: 'oblique',
                                                cursor: 'pointer',
                                                float: 'right'
                                            }}> 
                                                Pourquoi ces frais ?
                                    </span>
                                </InfoPopup>

                            </StyledTableCell>

                            <StyledTableCell align="left">
                                <b>{ serviceFees && readabilityNumber(serviceFees) + " " + receivedCurrency.symbol }</b>
                            </StyledTableCell>
                            
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Quantité nette reçue
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <b>{receivedQte +  " " + receivedCurrency.symbol}</b>
                            </StyledTableCell>
                        </StyledTableRow>
                    
                    </TableBody>
                </Table>
            </TableContainer>

            <ListInstructions
                instrctions={[
                    {
                        step: 1,
                        title: "Effectuez le paiement",
                        content: (
                            <>
                                Envoyez <b>{ readabilityNumber(sendQte) + " " + sendCurrency.symbol}</b> à l'adresse
                                <b>{" " + sendNetwork.address}</b>
                                {((sendNetwork.memoRequiredForSell == 1) && sendNetwork.memo) && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(sendNetwork.memo ? sendNetwork.memo : '', "Le memo a été copié dans le presse-paier.")}>{ sendNetwork.memo }</b></span>} sur le réseau <b>{sendNetwork.symbol + " (" + sendNetwork.name + ")"}</b>.
                            </>
                        )
                    },

                    {
                        step: 2,
                        title: "Marquez l'ordre comme étant payé",
                        content: (
                            <>
                                Cliquez sur <b>MARQUER PAYÉ</b> en bas de page. <br />
                                {   orderBasicInfos.status === 'waiting_payment' && 
                                    <Typography variant='caption' color='error'>Si vous ne marquez pas comme étant payé, l'ordre ne sera pas traité et finira par être annulé pour delai dépassé.</Typography>
                                }
                            </>
                        )
                    }
                ]}

                receptionAddressNote={
                    <>
                        Lorsque nous recevons votre paiement, vous recevrez <b className='cursor-pointer' onClick={() => copyClipboard(receivedQte as string, "La quantité a été copiée !")}>{ receivedQte + " " + receivedCurrency.symbol + " " }</b>
                        dans votre portefeuille crypto à l'adresse <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.blockchainAddress, "L'adresse a été copié dans le presse-paier.")}>{orderBasicInfos.blockchainAddress}</b>
                        {orderBasicInfos.memo && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.memo ? orderBasicInfos.memo : '', "Memo copié !")}>{ orderBasicInfos.memo }</b></span>} sur le réseau <b>{receivedNetwork.symbol}</b>.
                    </>
                }
                note={
                    <>
                        <b>Note :</b> Veuillez noter que si nous ne recevons pas votre paiement dans un delai de <b style={{color: 'red'}}>{ sendNetwork.maxTimePaymentLimit + ' minutes' }</b> à partir du moment où l'ordre a été créé, il sera automatiquent annulé.
                    </>
                }
            />

            <br />

            {
                orderBasicInfos.status === 'waiting_payment' &&

                <>
                    <MarkAsPaidBtn confirmPayment={props.confirmPayment} basicDatas={basicDatas} />

                    <br /> <br /> <br /> <br />

                    <Button type='button' variant='contained' color='inherit' startIcon={<CancelIcon fontSize='small' color='error'/>} onClick={props.cancelOrder}>
                        Annuler l'ordre
                    </Button>
                </>

            }

        </Box>
    )
}
