import React from 'react'
import { basicProps } from '../../utils/interfaces';
import { Typography, Box, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link, Route, Routes } from 'react-router-dom';
import Airdrops from './airdrops/Airdrops';
import Bonus from './bonus/Bonus';

export default function RewardsCenter(props: basicProps) {
    return (
        <Routes>

                <Route path='/' element={
                    <div className='text-center' style={{paddingTop:'50px', maxWidth:'500px', margin:'auto'}}>
                        
                        <Typography variant='h5' sx={{mb:4}}>
                            Centre de récompenses
                        </Typography>

                        <Box>
                            <List>

                                <Link to="/sponsorships/withdraw/" className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt:3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Mes commissions de parrainage"} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <Link to="/rewards-center/bonus/" className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt:3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Bonus"} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <Link to="/rewards-center/airdrops/" className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt:3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Airdrops"} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                            </List>
                        </Box>
                    </div>
                } />

                <Route path='/airdrops' element={<Airdrops { ...props } />}  />
                
                <Route path='/bonus' element={<Bonus { ...props } />}  />  

            </Routes>
    )
}
