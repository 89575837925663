import { useState, useEffect } from 'react';
import { 
    Box, Avatar, Button, SelectChangeEvent, 
    Chip, Typography, MobileStepper, useTheme, Stack
} from '@mui/material';
import {Link, useLocation } from 'react-router-dom';
import UserIsNotConnectedMessage from '../../components/UserIsNotConnectedMessage';
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import { currencyType, networkType, paymentMethodType } from '../../utils/interfaces';
import { matchIsValidTel } from 'mui-tel-input'
import { toast } from 'react-toastify';
import { MetaTags } from 'react-meta-tags';
import { cftTokenInfo, findPaymentMethod, isPC } from '../../functions/basicFunctions';
import SelectCrypto from './SelectCrypto';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import SelectNetwork from './SelectNetwork';
import WalletAddress from './WalletAddress';
import Quantity from './Quantity';
import PaymentMethod from './PaymentMethod';
import RecapOrder from './RecapOrder';
import KycInvitationPopup from '../../components/KycInvitationPopup';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TeamAvailabilityAlert from '../../components/TeamAvailabilityAlert';

const AutoPlaySwipeableViews = SwipeableViews;


interface tradeProps{
    basicDatas: any
}

export default function Trade(props: tradeProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;
    const location = useLocation();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [paymentMethodOwnerName, setPaymentMethodOwnerName] = useState<any>()

    const [networks, setNetworks] = useState<networkType[]>([])
    const [paymentMethods, setpaymentMethods] = useState<paymentMethodType[]>([]);

    const [cryptoCurrency, setCryptoCurrency] = useState<string>('');
    const [selectedCrypto, setSelectedCrypto] = useState<currencyType | null>();
    const [cryptoQuantity, setCryptoQuantity] = useState('');
    const [fiatQuantity, setFiatQuantity] = useState('')
    const [network, setNetwork] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<paymentMethodType | any>();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState<networkType | undefined>();
    const [action, setAction] = useState('');
    const [fiatTaxEstimation, setfiatTaxEstimation] = useState<number>(0);
    const [blockchainAddress, setBlockchainAddress] = useState<string>('');
    const [memo, setMemo] = useState<string>('');
    const [addessIsValid, setaddessIsValid] = useState<boolean>(true);
    const [cftReceptionAddress, setCftReceptionAddress] = useState('');
    const [suggestedNames, setSuggestedNames] = useState<any[]>([]);
    const [trendingCurrencies, setTrendingCurrencies] = useState<currencyType[]>()

    const [openKycInvitDialog, setOpenKycInvitDialog] = useState(false);

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const steps = action === 'buy' ? 
    [
        {
          title: 'Choisir une crypto',
          description: "Choisissez une crypto"
        },
        {
            title: 'Choisir le réseau blockchain',
            description: "Choisissez le réseau"
        },
        {
            title: "Adresse du portefeuille crypto",
            description: "Entrer l'adresse du portefeuille crypto"
        },
        {
            title: "Quantité",
            description: "Entrer la quantité à échanger"
        },
        {
            title: "Moyen de paiement",
            description: "Comment souhaitez-vous effectuer le paiement ?"
        },
        {
            title: "Récapitulatif",
            description: "Veuillez vérifier les informations avant de valider"
        }
    ]

    :

    [
        {
          title: 'Choisir une crypto',
          description: "Choisissez une crypto"
        },
        {
            title: 'Choisir le réseau blockchain',
            description: "Choisissez le réseau"
        },
        {
            title: "Quantité",
            description: "Entrer la quantité à échanger"
        },
        {
            title: "Moyen de paiement",
            description: "Comment souhaitez-vous effectuer le paiement ?"
        },
        {
            title: "Récapitulatif",
            description: "Veuillez vérifier les informations avant de valider"
        }
    ];

    useEffect(() => {

        if((activeStep > 0) && !selectedCrypto) {
            setActiveStep(0);
        }

        if((activeStep > 1) && !selectedNetwork) {
            setActiveStep(1);
        }

        if((activeStep > 3) && !selectedPaymentMethod) {
            setActiveStep(3);
        }

        if((activeStep > 4) && ((cryptoQuantity.length === 0) || (fiatQuantity.length === 0))) {
            setActiveStep(4);
        }
        
    }, [activeStep, selectedCrypto, selectedNetwork])
    

    useEffect(() => {
        setAction(location.pathname.match('sell') ? 'sell' : 'buy');
        updateQuantities();
    }, [location])

    useEffect(() => {

        checkValidAddress(blockchainAddress);
        if((network.length === 0) || (blockchainAddress.length === 0)) setaddessIsValid(true)

    }, [network, blockchainAddress])

    useEffect(() => {
        setBlockchainAddress('');
    }, [network])

    useEffect(() => {
        setCryptoQuantity('');
        setFiatQuantity('');
    }, [cryptoCurrency])

    useEffect(() => {
        setSelectedPaymentMethod(null);
        setPaymentMethod('');
        setNetwork('');
        setSelectedNetwork(undefined)
    }, [action])
    

    useEffect(() => {
        axiosApi.post("suggestions/payment_method_account_name_suggestions.php")
        .then(response => {
            if(response.data.status === 'success'){
                setSuggestedNames(response.data.suggestedNames);
            }
        })
    }, [basicDatas.isLoggedIn])

    useEffect(() => {

        if(selectedPaymentMethod && (fiatQuantity != '')) {

            if((action === 'buy') && (selectedPaymentMethod.percentagePurchaseTax != 0)){
                const percentagePurchaseTax =  parseFloat(selectedPaymentMethod.percentagePurchaseTax);
                let tax = parseFloat(fiatQuantity) * percentagePurchaseTax / 100;
                tax = tax <= 5000 ? tax : 5000;
                tax = tax >= 200 ? tax : 200;
                setfiatTaxEstimation(tax);
            }

            else if ((action === 'sell') && (selectedPaymentMethod.percentageSaleTax != 0)) {
                const percentageSaleTax =  parseFloat(selectedPaymentMethod.percentageSaleTax);
                let tax = parseFloat(fiatQuantity) * percentageSaleTax / 100;
                tax = tax <= 5000 ? tax : 5000;
                tax = tax >= 200 ? tax : 200;
                setfiatTaxEstimation(tax)
            }
        }

        else {
            setfiatTaxEstimation(0);
        }
      
    }, [fiatQuantity, paymentMethod])

    useEffect(() => {
        
        if(selectedPaymentMethod && (selectedPaymentMethod.alertMessageForBuy && (selectedPaymentMethod.alertMessageForBuy.length !== 0)) && (action === 'buy')) {

            let alternativePaymentMethod = findPaymentMethod(selectedPaymentMethod.alternativeForBuy, paymentMethods)
            
            basicDatas.dialogManager({
                dialogTitle: '',
                dialogText: (
                    <Box sx={{
                        textAlign: 'center'
                    }}>

                        <WarningAmberIcon
                                color='warning'
                                sx={{
                                    margin: 'auto',
                                    fontSize: 70,
                                    mb: 2
                                }} />

                        <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                            Alerte de risque
                        </Typography>

                        <Typography
                            sx={{mb: 3}}
                        >
                            { selectedPaymentMethod.alertMessageForBuy }
                        </Typography>


                        <Typography variant='caption' sx={{fontWeight: 'bold'}}>
                            Nous recommandons le moyen de paiement suivant :
                        </Typography>

                        {
                            alternativePaymentMethod &&
                            <Stack
                                direction={'row'}
                                spacing={2}
                                alignItems={'center'}
                                onClick={() => {
                                        setPaymentMethod(alternativePaymentMethod?.symbol as string);
                                        setSelectedPaymentMethod(alternativePaymentMethod);
                                        let dialogAgreeBtn = document.getElementById('dialogAgreeBtn')
                                        if(dialogAgreeBtn) {
                                            dialogAgreeBtn.click();
                                        }
                                    }
                                }
                                sx={{
                                    padding: 2,
                                    boxShadow: 1,
                                    borderRadius: 2,
                                    mt: 1,
                                    cursor: 'pointer'
                                }}
                            >
                                <Avatar 
                                    src={alternativePaymentMethod.logo}
                                />
                                <Typography>
                                    {alternativePaymentMethod.name}
                                </Typography>
                            </Stack>
                        }

                    </Box>
                ),
                dialogAgreeBtnColor: 'error',
                dalogCloseBtnText: 'Annuler',
                dialogAgreeBtnText: 'Je comprends le risque'
              }, (response) => {
                
                if(response === 'no') {
                    setPaymentMethod('');
                    setSelectedPaymentMethod(null);
                }
          
            })
        }


        if(selectedPaymentMethod && (selectedPaymentMethod.symbol === 'UBA_CG') && (action === 'buy')) {

            let alternativePaymentMethod = findPaymentMethod(selectedPaymentMethod.alternativeForBuy, paymentMethods)
            
            basicDatas.dialogManager({
                dialogTitle: '',
                dialogText: (
                    <Box sx={{
                        textAlign: 'center'
                    }}>

                        <Avatar
                            src={selectedPaymentMethod.logo}
                            sx={{
                                margin: 'auto',
                                mb: 2,
                                width: 70,
                                height: 70
                            }} 
                        />

                        <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                            { selectedPaymentMethod.name }
                        </Typography>

                        <Typography
                            sx={{mb: 3}}
                        >
                            Pour utiliser ce moyen de paiement, veuillez contacter le support pour être guidé.
                            <br /><br />
                            Notez également que le minimum par transaction est de 100'000 FCFA.
                            <br /><br />
                            Veuillez contacter le support via WhatsApp ici : <a href="https://wa.me/+237650839431/?text=Bonjour, je souhaite utiliser UBA Congo Brazza pour effectuer un achat." target='_blank'>+237650839431</a>
                        </Typography>


                        <Typography variant='caption' sx={{fontWeight: 'bold'}}>
                            Nous recommandons le moyen de paiement suivant :
                        </Typography>

                        {
                            alternativePaymentMethod &&
                            <Stack
                                direction={'row'}
                                spacing={2}
                                alignItems={'center'}
                                onClick={() => {
                                        setPaymentMethod(alternativePaymentMethod?.symbol as string);
                                        setSelectedPaymentMethod(alternativePaymentMethod);
                                        let dialogAgreeBtn = document.getElementById('dialogAgreeBtn')
                                        if(dialogAgreeBtn) {
                                            dialogAgreeBtn.click();
                                        }
                                    }
                                }
                                sx={{
                                    padding: 2,
                                    boxShadow: 1,
                                    borderRadius: 2,
                                    mt: 1,
                                    cursor: 'pointer'
                                }}
                            >
                                <Avatar 
                                    src={alternativePaymentMethod.logo}
                                />
                                <Typography>
                                    {alternativePaymentMethod.name}
                                </Typography>
                            </Stack>
                        }

                    </Box>
                ),
                dialogAgreeBtnColor: 'info',
                dalogCloseBtnText: 'Annuler',
                dialogAgreeBtnText: "J'ai compris"
            }, () => {
                setPaymentMethod('');
                setSelectedPaymentMethod(null);          
            })
        }

    }, [selectedPaymentMethod])
    

    useEffect(() => {
        getNetworks();
        getPaymentMethods(); 
    }, [])

    useEffect(() => {

        if(selectedCrypto && cryptoCurrency) {
            
            cryptoCurrency && getCurrencyInfo(cryptoCurrency)

            if(selectedCrypto.allowed_networks && (selectedCrypto.allowed_networks.length === 1) && networks) {

                const findNetwork = networks.filter(_network => selectedCrypto.allowed_networks &&  _network.symbol === selectedCrypto.allowed_networks[0].network)
                if(findNetwork.length !== 0) {
                    handleNetworkChange(findNetwork[0].symbol)
                }
            }
        }
        
    }, [cryptoCurrency])
    

    useEffect(() => {

        if(selectedCrypto && cryptoCurrency && selectedPaymentMethod && paymentMethod) {
            getCurrencyInfo(cryptoCurrency, selectedPaymentMethod);
        }
        
    }, [paymentMethod])

    useEffect(() => {
        
        axiosApi.post('ctr/currencies/get-trending-currencies.php')
        .then(response => {

            if(response.data.status === 'success') {
                setTrendingCurrencies(response.data.trendingCurrencies);
            }
        })

    }, [])
    

    const handleNext = () => {

        if(activeStep === 0) {
            if(!selectedCrypto) {
                toast.error("Veuillez sélectionner une crypto.", {position: 'top-center'});
                return;
            }
        }

        if(activeStep === 1) {
            if(!selectedNetwork) {
                toast.error("Veuillez sélectionner le réseau.", {position: 'top-center'});
                return;
            }
        }

        if((action === 'buy') && (activeStep === 2)) {

            if(blockchainAddress.length === 0) {
                toast.error("Veuillez choisir une adresse de portefeuille", { position: 'top-center' });
                return;
            }

            if(selectedNetwork && (selectedNetwork.memoRequiredForBuy == 1) && (action === 'buy') && (memo.length === 0)){

                basicDatas.dialogManager({
                    dialogTitle: 'Memo vide',
                    dialogText: "La plupart des exchanges/portefeuilles exigent un MEMO pour que votre " + selectedCrypto?.symbol + " soit correctement crédité. Vous avez laissé le MEMO vide. Dans ce cas, veuillez vous assurer que la plateforme/portefeuille de réception n'exige pas de MEMO sinon vos fonds seront perdus.",
                    dialogAgreeBtnColor: 'info',
                    dialogAgreeBtnText: "Continuer sans memo"
                    }, (res) => {
                    
                    if(res === 'ok') {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                
                })

                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            
        }

        if(((action === 'buy') && (activeStep === 3)) || ((action === 'sell') && (activeStep === 2))) {

            if(!selectedPaymentMethod) {
                toast.error("Veuillez choisir une méthode de paiement.", {position: 'top-center'});
                return;
            }

            else if((selectedPaymentMethod.symbol !== cftTokenInfo.symbol) && (selectedPaymentMethod.symbol !== 'CF_PAY')){
                if(phoneNumber.length === 0) {
                    toast.error("Veuillez entrer votre numéro mobile money.", {position: 'top-center'});
                    return;
                }

                if(!paymentMethodOwnerName || (paymentMethodOwnerName.length === 0)) {
                    toast.error("Veuillez entrer le nom associé à votre compte mobile money.", {position: 'top-center'});
                    return;
                }
            }

            if(action === 'sell') {
                if((selectedPaymentMethod.symbol === cftTokenInfo.symbol) && (cftReceptionAddress.length ===0)) {
                    toast.error("Veuillez entrer votre adresse de réception " + cftTokenInfo.symbol + " sur le réseau BEP20.", {position: 'top-center'});
                    return;
                } 
            }
        }

        if(((action === 'buy') && (activeStep === 4)) || ((action === 'sell') && (activeStep === 3))) {

            if(!selectedPaymentMethod || !selectedCrypto){
                setActiveStep(1);
                return;
            }
            
            if(cryptoQuantity.length === 0) {
                toast.error("Veuillez entrer la quantité " + selectedCrypto?.symbol, {position: 'top-center'});
                return;
            }

            else if(fiatQuantity.length === 0) {
                toast.error("Veuillez entrer la quantité FIAT", {position: 'top-center'});
                return;
            }

            if((action === 'buy') && (parseFloat(fiatQuantity) > parseFloat(selectedPaymentMethod.maxBuyAmount))) {
                toast.error("Maximum XAF dépassé : " + selectedPaymentMethod.maxBuyAmount, {position: 'top-center'});
                return;
            }

            if((action === 'sell') && (parseFloat(fiatQuantity) > parseFloat(selectedPaymentMethod.maxSellAmount))) {
                toast.error("Maximum XAF dépassé : " + selectedPaymentMethod.maxSellAmount, {position: 'top-center'});
                return;
            }
        }
        
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };
    

    const updateQuantities = () => {

        if(selectedCrypto && selectedCrypto.purchasePrice && selectedCrypto.salePrice) {
            var fiatQte = action === 'buy' ? parseFloat(cryptoQuantity) * selectedCrypto.purchasePrice : parseFloat(cryptoQuantity) * selectedCrypto.salePrice;

            if(cryptoQuantity.length !== 0) setFiatQuantity("" + fiatQte)
        }
    }
    

    const handleChangeCryptoCurrency = (currency: currencyType | null) => {

        if(!currency) {
            setPaymentMethod('');
            setSelectedNetwork(undefined);
            setCryptoCurrency('');
            setSelectedCrypto(null);
            return;
        }
        
        if((currency.symbol === cftTokenInfo.symbol) && (selectedPaymentMethod?.symbol === cftTokenInfo.symbol)){
            setPaymentMethod('')
        }

        setCryptoCurrency(currency.symbol);
        setSelectedCrypto(currency);
        setNetwork('');
        setSelectedNetwork(undefined);

    }

    const handleCryptoQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if(selectedCrypto && selectedCrypto.purchasePrice && selectedCrypto.salePrice) {

            const value = parseFloat(event.target.value) as number;

            setCryptoQuantity(event.target.value); 
            var fiatQte = action === 'sell' ? value * selectedCrypto.purchasePrice : value * selectedCrypto.salePrice;
            setFiatQuantity("" + fiatQte)
        } 
    }

    const handleFiatQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if(selectedCrypto && selectedCrypto.purchasePrice && selectedCrypto.salePrice) {

            const value = parseFloat(event.target.value) as number;

            setFiatQuantity(event.target.value); 
            var cryptoQte = action === 'sell' ? value / selectedCrypto.purchasePrice : value / selectedCrypto.salePrice;
            cryptoQte = cryptoQte.toFixed(6) as unknown as number;
            setCryptoQuantity("" + cryptoQte)
        } 
    }

    const handlePaymentMethodChange = (e: SelectChangeEvent) => {

        const matchedPaymentMethod = paymentMethods.filter((item) => item.symbol === e.target.value);
        if(matchedPaymentMethod.length !== 0){

            const matchedMethod = matchedPaymentMethod[0];

            if((action === 'buy') && (matchedMethod.percentagePurchaseTax != 0)){
                basicDatas.dialogManager({
                    dialogTitle: 'Une taxe sera appliquée',
                    dialogText: 'Une taxe sera appliquée pour le moyen de paiement que vous avez choisi. Raison : ' + matchedMethod.purchaseTaxReason,
                    dialogAgreeBtnColor: 'info',
                    dialogAgreeBtnText: "J'ai compris"
                  }, (response) => {
                    
                    if(response === 'ok') {
                        setSelectedPaymentMethod(matchedMethod);
                        setPaymentMethod(e.target.value as string);
                    }
              
                })
            }

            else if  ((action === 'sell') && (matchedMethod.percentageSaleTax != 0)) {
                basicDatas.dialogManager({
                    dialogTitle: 'Une taxe sera appliquée',
                    dialogText: "Une taxe sera appliquée pour le moyen de payement " + matchedMethod.symbol + " (" + matchedMethod.name + "). Raison : " + matchedMethod.saleTaxReason,
                    dialogAgreeBtnColor: 'info',
                    dialogAgreeBtnText: "Ok j'ai compris"
                  }, (response) => {
                    
                    if(response === 'ok') {
                        setSelectedPaymentMethod(matchedMethod);
                        setPaymentMethod(e.target.value as string);
                    }
              
                })
            }

            else {
                setSelectedPaymentMethod(matchedMethod);
                setPaymentMethod(e.target.value as string);
            }
            
        }

    }

    const getNetworks = () => {
        axiosApi.post('ctr/networks/get-networks.php')
        .then(response => {
            if(response.data.status === 'success'){
                setNetworks(response.data.networks);
            }
        })
    }

    const getPaymentMethods = () => {
        axiosApi.post('ctr/payment-methods/get-payment-methods.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setpaymentMethods(response.data.paymentMethods);
            }
        })
    }

    const getCurrencyInfo = (currencyID: number | string, _paymentMethod?: paymentMethodType) => {

        const data = {
            symbol: currencyID,
            paymentMethod,
            loaderText: "Please wait..."
        }

        axiosApi.post('ctr/currencies/get-currency-infos.php', QueryString.stringify(data))
        .then(response => {
            if(response.data.status === 'success'){
                setSelectedCrypto(response.data.currencyData);
                setCryptoCurrency(response.data.currencyData.symbol);
                setCryptoQuantity('');
                setFiatQuantity('');
                if(!_paymentMethod) setActiveStep(1);
            }
        })
    }

    const handleBlockchainAddressChange = (value: string) => {

        setBlockchainAddress(value);

        if(selectedCrypto) {
            checkValidAddress(value);
        }

    }


    const checkValidAddress = (addressValue: string) => {

        const findAllowedNetwork = selectedCrypto?.allowed_networks?.find((n) => selectedNetwork?.symbol === n.network);
            
        if(findAllowedNetwork && findAllowedNetwork.addressRegex) {
            const addressRegex = new RegExp(findAllowedNetwork.addressRegex, 'g');
            
            if((addressValue.length > 0) && !addressRegex.exec(addressValue)) {
                setaddessIsValid(false);
            }

            else {
                setaddessIsValid(true);
            }
        }

    }

    const handleNetworkChange = (network_symbol: string) => {

        const findNetworkInfos = networks.filter((item) => item.symbol === network_symbol);
        const findInAllowedNetworks = selectedCrypto?.allowed_networks?.filter(allowed_network => allowed_network.network === network_symbol);
        
        if(findInAllowedNetworks 
            && findInAllowedNetworks[0]
            && (action === 'buy')
            && (
                    findInAllowedNetworks[0].buyAlertMessage 
                    && (findInAllowedNetworks[0].buyAlertMessage !== '')
                )
        ){

            basicDatas.dialogManager({
                dialogTitle: "Note importante",
                dialogText: findInAllowedNetworks[0].buyAlertMessage,
                dialogAgreeBtnColor: 'primary',
                dialogAgreeBtnText: "J'ai compris",
                dalogCloseBtnText: 'Annuler'
                }, (response) => {
                
                if(response === 'ok') {
        
                    if(findNetworkInfos.length !== 0) {
                        setSelectedNetwork(findNetworkInfos[0])
                    }
            
                    setNetwork(network_symbol);
                    
                }
        
            })
        }

        else if (findInAllowedNetworks 
                    && findInAllowedNetworks[0]
                    && (action === 'sell')
                    && (
                            findInAllowedNetworks[0].sellAlertMessage 
                            && (findInAllowedNetworks[0].sellAlertMessage !== '')
                        )
                ){

            basicDatas.dialogManager({
                dialogTitle: "Note importante",
                dialogText: findInAllowedNetworks[0].sellAlertMessage,
                dialogAgreeBtnColor: 'primary',
                dialogAgreeBtnText: "J'ai compris",
                dalogCloseBtnText: 'Annuler'
                }, (response) => {
                
                if(response === 'ok') {
        
                    if(findNetworkInfos.length !== 0) {
                        setSelectedNetwork(findNetworkInfos[0])
                    }
            
                    setNetwork(network_symbol);
                    
                }
        
            })
        }

        else {

            if(findNetworkInfos.length !== 0) {
                setSelectedNetwork(findNetworkInfos[0])
            }
    
            setNetwork(network_symbol);
        }

        checkValidAddress(blockchainAddress);


        if((action === 'buy') && findInAllowedNetworks && findInAllowedNetworks[0] && (findInAllowedNetworks[0].congested)) {
            basicDatas.dialogManager({
                dialogTitle: "Réseau congestionné",
                dialogText: <Typography>
                    Le réseau <b>{findNetworkInfos[0].symbol + " (" + findNetworkInfos[0].name + ")" }</b>  est actuellement congestionné. <br />
                    <Typography variant='caption'>
                        Cela signifie que le réseau est surchargé et que les transactions peuvent durer plus longtemps que d'habitude.
                        <br />
                        Les frais de transactions peuvent également augmenter considérablement.
                    </Typography>
                </Typography>,
                dialogAgreeBtnColor: 'primary',
                dialogAgreeBtnText: "J'ai compris",
                dalogCloseBtnText: ' '
            }, () => {})
        }

    }

    const createOrder = () => {

        if(!selectedCrypto) {
            toast.warn("Aucune devise n'est sélectionnée !");
            return;
        }

        if(!matchIsValidTel(phoneNumber) && (selectedPaymentMethod.symbol !== 'CF_PAY') && (selectedPaymentMethod.symbol !== cftTokenInfo.symbol)){
            toast.error("Veuillez entrer un numéro de téléphone valide.");
            return;
        }
 

        let data = {
            loaderText: 'Création de votre ordre en cours...',
            action,
            cryptoCurrency,
            network,
            blockchainAddress,
            memo,
            cryptoQte: cryptoQuantity,
            paymentMethod,
            paymentMethodNumber: phoneNumber,
            paymentMethodOwnerName,
            cftReceptionAddress
        }

        axiosApi.post('orders/create.php', QueryString.stringify(data))
        .then(response => {

            if(response.data.action === 'INVITE_FOR_KYC') {
                setOpenKycInvitDialog(true)
            }

            if(response.data.status === 'success'){
                const orderID = response.data.orderID;
                window.location.href = "/orders/" + orderID + '/reglement/';
            }
        })

    }

    if(!basicDatas.isLoggedIn) return (
        <div className='text-center' style={{maxWidth:'500px', margin: 'auto', marginTop:'60px'}}>
            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Créer un ordre</title>
                <meta name="description" content={basicDatas.appName + " - Crér un ordre"} />
                <meta property="og:title" content={basicDatas.appName + " - Crér un ordre"} />
            </MetaTags>
            <UserIsNotConnectedMessage message="Pour créer un ordre, veuillez vous identifier pour nous permettre d'avoir les informations necessaires pour votre transaction." />
        </div>
    )

    return (
        <div style={{maxWidth:'450px', margin: 'auto', marginTop:'60px'}}>

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Créer un ordre</title>
                <meta name="description" content={basicDatas.appName + " - Crér un ordre"} />
                <meta property="og:title" content={basicDatas.appName + " - Crér un ordre"} />
            </MetaTags>

            <KycInvitationPopup 
                basicDatas={basicDatas} 
                openKycInvitDialog={openKycInvitDialog}
                handleCloseKycInvitDialog={() => setOpenKycInvitDialog(false)}
            />

            <TeamAvailabilityAlert basicDatas={basicDatas} />

            <div className='text-center'>

                <Link to="/trade/buy/" style={{textDecoration:'none'}}>
                    <Button variant={action !== 'sell' ? 'contained' : 'outlined'}
                            sx={{
                                borderRadius: '0px',
                                borderBottomLeftRadius: '20px',
                                opacity: action !== 'sell' ? 1 : 0.7
                            }}>Acheter</Button>
                </Link>

                <Link to="/trade/sell/" style={{textDecoration:'none'}}>
                    <Button variant={action === 'sell' ? 'contained' : 'outlined'}
                            color="error"
                            sx={{
                                borderRadius: '0px',
                                borderTopRightRadius: '20px',
                                opacity: action === 'sell' ? 1 : 0.7
                            }}>Vendre</Button>
                </Link>
            </div>
            
            <br /><br />
            
            <Box  
                sx={{ 
                    mt: 1, 
                    boxShadow: 2, 
                    borderRadius: 3,
                    minHeight: 350,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >

                {
                    selectedCrypto 

                    ?
                
                    <Typography
                            sx={{
                                textAlign: 'center',
                                mb: 2,
                                mt: 2
                            }}>
                        <Chip 
                            icon={<Avatar src={selectedCrypto.logo} sx={{width: 21, height: 21, mr: 1}} />}
                            label={ selectedNetwork ? selectedCrypto.symbol + "_" + selectedNetwork.symbol : selectedCrypto.symbol } 
                            onClick={() => setActiveStep(0)}
                            sx={{
                                bgcolor: 'transparent',
                                ml: 2,
                                fontSize: '11px',
                                cursor: 'pointer',
                                boxShadow: 3,
                                fontWeight: 'bold'
                            }} 
                            variant="filled"
                            size='small' />
                    </Typography>

                    :

                    <Typography
                            sx={{
                                textAlign: 'center',
                                mb: 2,
                                mt: 2
                            }}>
                        <Chip 
                            icon={
                                <Avatar 
                                    src="cf" 
                                    alt='CF'
                                    sx={{
                                        width: 21, 
                                        height: 21, 
                                        mr: 1}} 
                                    />
                            }
                            label="....." 
                            sx={{
                                bgcolor: 'transparent',
                                ml: 2,
                                fontSize: '11px',
                                cursor: 'pointer',
                                boxShadow: 3,
                                fontWeight: 'bold'
                            }} 
                            variant="filled"
                            size='small' />
                    </Typography>
                }

                <Box
                    sx={{
                        flex: '1 0 auto',
                        p: 2,
                        height: isPC ? 300 : 'auto',
                        overflow: 'auto'
                    }}>

                    <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                        >
                            {
                            
                                steps.map((step, index) => {

                                    return (
                            
                                        <div key={step.title}>
                                            {  
                                                Math.abs(activeStep - index) <= 2 
                                                
                                                ? 
                                                
                                                <>

                                                    {
                                                        activeStep === 0 &&
                                                        <SelectCrypto 
                                                            action={ action } 
                                                            selectedCrypto={ selectedCrypto }
                                                            trendingCurrencies={ trendingCurrencies }
                                                            handleChangeCryptoCurrency={handleChangeCryptoCurrency}
                                                            setActiveStep={setActiveStep}
                                                            />
                                                    }

                                                    {
                                                        activeStep === 1 &&
                                                        <SelectNetwork 
                                                            {...props}
                                                            action={action} 
                                                            network={network}
                                                            selectedCrypto={selectedCrypto}
                                                            networks={networks}
                                                            selectedNetwork={selectedNetwork}
                                                            handleNetworkChange={handleNetworkChange} />
                                                    }

                                                    {
                                                        ((action === 'buy') && (activeStep === 2)) &&

                                                        <WalletAddress
                                                            basicDatas={basicDatas}
                                                            blockchainAddress={blockchainAddress} 
                                                            action={action}
                                                            selectedCrypto={selectedCrypto}
                                                            selectedNetwork={selectedNetwork}
                                                            addessIsValid={addessIsValid}
                                                            setBlockchainAddress={setBlockchainAddress}
                                                            memo={memo}
                                                            setMemo={setMemo}
                                                            network={network}
                                                            handleBlockchainAddressChange={handleBlockchainAddressChange}
                                                            />
                                                    }

                                                    {
                                                        (((action === 'buy') && (activeStep === 3)) || ((action === 'sell') && (activeStep === 2))) &&
                                                        <PaymentMethod
                                                            basicDatas={basicDatas}
                                                            action={action}
                                                            paymentMethod={paymentMethod}
                                                            paymentMethods={paymentMethods}
                                                            selectedCrypto={selectedCrypto}
                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                            phoneNumber={phoneNumber}
                                                            setPhoneNumber={setPhoneNumber}
                                                            paymentMethodOwnerName={paymentMethodOwnerName}
                                                            suggestedNames={suggestedNames}
                                                            cftReceptionAddress={cftReceptionAddress} 
                                                            setCftReceptionAddress={setCftReceptionAddress}
                                                            setPaymentMethodOwnerName={setPaymentMethodOwnerName}
                                                            handlePaymentMethodChange={handlePaymentMethodChange} />
                                                    }

                                                    {
                                                        (((action === 'buy') && (activeStep === 4)) || ((action === 'sell') && (activeStep === 3))) &&
                                                        <Quantity
                                                            basicDatas={basicDatas}
                                                            action={action}
                                                            cryptoQuantity={cryptoQuantity}
                                                            fiatQuantity={fiatQuantity}
                                                            fiatTaxEstimation={fiatTaxEstimation}
                                                            selectedCrypto={selectedCrypto}
                                                            selectedNetwork={selectedNetwork}
                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                            handleCryptoQuantityChange={handleCryptoQuantityChange}
                                                            handleFiatQuantityChange={handleFiatQuantityChange} />
                                                    }

                                                    {
                                                        (((action === 'buy') && (activeStep === 5)) || ((action === 'sell') && (activeStep === 4))) &&
                                                        <RecapOrder
                                                            action={action}
                                                            selectedCrypto={selectedCrypto}
                                                            selectedNetwork={selectedNetwork}
                                                            blockchainAddress={blockchainAddress}
                                                            memo={memo}
                                                            cryptoQuantity={cryptoQuantity}
                                                            fiatQuantity={fiatQuantity}
                                                            phoneNumber={phoneNumber}
                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                            paymentMethodOwnerName={paymentMethodOwnerName}
                                                            />
                                                    }
                                                </>

                                                : 
                                                    null
                                                }
                                        </div>
                                    )}
                                )
                            }
                    </AutoPlaySwipeableViews>

                </Box>

                <MobileStepper
                    variant="progress"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                        boxShadow: 2
                    }}
                    nextButton={

                        activeStep !== (steps.length - 1)

                        ?
                        <Button 
                            size="small" 
                            onClick={handleNext} 
                            disabled={activeStep === (steps.length - 1)}
                            sx={{ml: isPC ? 1 : 2, fontWeight: 'bold'}}
                            >
                            Continuer
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>

                        :

                        selectedCrypto 
                            
                            ?

                            <Button
                                variant="contained"
                                color={action === 'sell' ? 'error' : 'info'}
                                sx={{ ml: isPC ? 1 : 2 }}
                                onClick={createOrder}
                                >
                                {action === 'sell' ? 'Vendre_' + selectedCrypto.symbol : 'Acheter_' + selectedCrypto.symbol}
                            </Button>

                            :

                            <Typography variant='caption' color='error' sx={{ml: 1}}>
                                Aucune devise sélectionnée
                            </Typography>

                    }
                    backButton={
                        <Button 
                            size="small" 
                            onClick={handleBack} 
                            disabled={activeStep === 0}
                            sx={{mr: isPC ? 1 : 2}}
                            >
                            {
                                theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) 
                                : (
                                    <KeyboardArrowLeft />
                                )
                            }
                            Retour
                        </Button>
                    }
                />

            </Box>

        </div>
    )
}

