import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import axiosApi from '../functions/axiosApi';
import GoogleIcon from '@mui/icons-material/Google';
import { Dialog, DialogActions, Box, Grid, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import QueryString from 'qs';
import { MetaTags } from 'react-meta-tags';
import { basicProps } from '../utils/interfaces';
import { urlParams } from '../functions/basicFunctions';


export default function SignUp(props: basicProps) {

  const basicDatas = props.basicDatas;
  const navigate = useNavigate();
  const location = useLocation();
  const sponsorID = urlParams().s;

  const [open, setOpen] = React.useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailCode, setEmailCode] = useState('');

  const handleOpenConfirmMailModal = () => setOpen(true);
  const handleCloseConfirmMailModal = () => setOpen(false);

  const googlePrompt = () => {
    const myWindow = window as any;
    if(myWindow.google) {
      const google = myWindow.google;
      google.accounts.id.prompt();
    }

  }

  const signWidthGoogle = (googleResponse) => {

    const credential = googleResponse.detail.credential;
    
    axiosApi.post('auth/signup_with_google.php', QueryString.stringify({
      credential,
      sponsorID,
      loaderText: "Création du compte via Google..."
    })).then(response => {
      if(response.data.status === 'success'){
        basicDatas.setIsLoggedIn(true);
        basicDatas.setAuthUser(response.data.user);
        navigate("/account/");
      }
    })
  }

  useEffect(() => {

    window.addEventListener('user_sign_with_google', signWidthGoogle)
    return () => {
      window.removeEventListener('user_sign_with_google', signWidthGoogle)
    }
  }, [location])
  

  let verifyEmailCode = () => {

    const data = {
      emailAddress, 
      emailCode,
      loaderText: "Vérification de l'adresse e-mail..."
    }

    axiosApi.post('auth/verify_email_code.php', QueryString.stringify(data))
    .then(response => {
      
      if(response.data.status === "success") {
        const create_account_btn = document.getElementById('create_account_btn')
        
        if(create_account_btn){
          create_account_btn.click();
          handleCloseConfirmMailModal();
        }
      }

    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append('sponsorID', sponsorID);
    data.append('loaderText', "Traitement en cours...");

    const email = data.get('email') as string;
    const lastName = data.get('lastName') as string;
    const firstName = data.get('firstName') as string;
    const password = data.get('password') as string;
    const confirmPassword = data.get('confirmPassword') as string;

    if((lastName.length === 0) && (firstName.length === 0)){
      toast.error("Veuillez entrer votre nom et prénom ou au moins votre nom!", {autoClose:15000});
      return;
    }

    else if(firstName.length === 0){
      toast.error("Veuillez entrer votre nom", {autoClose:15000});
      return;
    }

    else if(email.length === 0){
      toast.error("Veuillez entrer votre adresse E-mail.");
      return;
    }

    else if(password.length === 0){
      toast.error('Veuillez choisir un mot de passe.');
      return;
    }

    else if(password.length < 6){
      toast.warning("Votre mot de passe doit contenir au moins 6 (six) caratères.");
      return;
    }

    else if(confirmPassword.length === 0){
      toast.error("Veuillez confirmer votre mot de passe.");
      return;
    }

    else if(password !== confirmPassword){
      toast.error("Les deux mot de passe doient être identiques.");
      return;
    }

    axiosApi.post('auth/signup.php', data)
    .then(response => {

      const responseData = response.data;

      const show_error = document.getElementById('show_error')
      if(!response.data.status && show_error) {
        show_error.innerHTML = response.data
      } 

      else if(show_error) {
        show_error.innerHTML = ''
      }
      
      if((response.data.status === "success") && (response.data.message.length !== 0)) {
        basicDatas.setIsLoggedIn(true);
        basicDatas.setAuthUser(response.data.user);
        navigate("/account/");
      }

      if(responseData.action === "display_confirm_mail_modal"){
        handleOpenConfirmMailModal();
      }

    })

  };


  return (

      <div style={{maxWidth:'400px', margin:'auto'}}>

        <MetaTags id="signup">
            <title>Créer un compte</title>
            <meta name="description" content={basicDatas.appName + " - Créer un compte"} />
            <meta property="og:title" content={basicDatas.appName + " - Créer un compte"} />
        </MetaTags>
        
        <Box
          sx={{
            marginTop: window.screen.width > 700 ? 8 : 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          
          <Typography component="h1" variant="h5" sx={{mb:6}}>
            Créer un compte
          </Typography>

          <Button onClick={googlePrompt}
                  variant="contained"
                  color='error'
                  fullWidth
                  startIcon={<GoogleIcon fontSize='small' />}>
            Continuer avec Google
          </Button>

          <Divider sx={{mt:3, mb:1, width:'100%'}}>Ou continuer via email</Divider>

          <Box component="form" noValidate onSubmit={handleSubmit}>
            <TextField
              autoComplete="lastName"
              name="lastName"
              required
              fullWidth
              id="lastName"
              label="Nom"
              margin='normal'
            />
        
            <TextField
              required
              fullWidth
              id="firstName"
              label="Prénom"
              name="firstName"
              autoComplete="firstName"
              margin='normal'
            />

            <TextField
              required
              fullWidth
              id="email"
              label="Adresse email"
              name="email"
              autoComplete="email"
              margin='normal'
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          
            <TextField
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="new-password"
              margin='normal'
            />
        
            <TextField
              required
              fullWidth
              name="confirmPassword"
              label="Confirmer le mot de passe"
              type="password"
              id="confirm_password"
              autoComplete="new-password"
              margin='normal'
              sx={{mb:2}}
            />


            <Grid container justifyContent="flex-end">
              <Grid item className='small'>
                En vous inscrivant, vous acceptez nos <a href="/terms/cgu/" target='_blank'>Conditions Générales d'Utilisation (CGU)</a>.
              </Grid>
            </Grid>
           
            <Button
              type="submit"
              id="create_account_btn"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Créer le compte
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item className='small'>
                Déjà membre? <Link to="/auth/login/">Connectez-vous</Link>
              </Grid>
            </Grid>

          </Box>
        </Box>

        <Dialog open={open}>
          <DialogTitle>Vérifier l'adresse Email</DialogTitle>
          <DialogContent>
            <DialogContentText style={{fontSize:'small'}}>
              Nous vous avons envoyé un code à 5 (cinq) caractères à l'adresse <span style={{fontWeight:'bold'}}>{emailAddress}</span> . <br />
              Veuillez entrer ce code pour confirmer votre adresse Email.
            </DialogContentText>

            <br />

            <TextField
              autoFocus
              onChange={(e) => setEmailCode(e.target.value)}
              margin="dense"
              id="email_code"
              label="Code de confirmation"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={handleCloseConfirmMailModal}>Annuler</Button>
            <Button onClick={verifyEmailCode}>Vérifier</Button>
          </DialogActions>
        </Dialog>
        
      </div>
   
  );
}