import { useEffect } from 'react';
import { 
    Alert, AlertTitle, Avatar, Button, Chip, Tooltip, 
    AvatarGroup, Stack, Typography ,
    TableContainer, Table, Paper, TableBody, TableRow, Box
} from '@mui/material';
import { useState } from 'react'
import { basicProps, orderType, paymentMethodType } from '../../../utils/interfaces';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ShowOrderStaus from './ShowOrderStaus';
import { Link, useLocation } from 'react-router-dom';
import { copyClipboard, isPC, readabilityNumber } from '../../../functions/basicFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import axiosApi from '../../../functions/axiosApi';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { HashLink } from 'react-router-hash-link';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import InfoPopup from '../../../components/InfoPopup';
import AdminMessage from './AdminMessage';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ListInstructions from './ListInstructions';

interface buyInstructionsProps extends basicProps {
    orderData: orderType;
    confirmPayment: () => void,
    cancelOrder: () => void
}

interface paymentMethodCheckableType extends paymentMethodType {
    cpoied: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function ExternalBuyInstructions(props: buyInstructionsProps) {

    const location = useLocation();
    const basicDatas = props.basicDatas;

    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const paymentMethod = orderData.paymentMethod;
    const cryptoCurrency = orderData.cryptoCurrency;
    const network = orderData.network;

    let amountReceived = orderBasicInfos.networkFee ? (parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(orderBasicInfos.networkFee + '')) : orderBasicInfos.cryptoQte;
    amountReceived = readabilityNumber(amountReceived);

    const [acceptExternalPaymentMethods, setAcceptExternalPaymentMethods] = useState<paymentMethodCheckableType[]>([])    

    useEffect(() => {
        
        axiosApi.post("orders/get-accept-external-payment-methods.php")
        .then(response => {
            if(response.data.status === 'success') {
                setAcceptExternalPaymentMethods(response.data.acceptExternalPaymentMethods)
            }
        })

    }, [])

    const copyNumber = (method: paymentMethodCheckableType) => {
        
        copyClipboard(method.address, "Numéro " + method.name + " copié !")
        
        let a = [...acceptExternalPaymentMethods];

        a.forEach(itemMethod => {
            itemMethod.cpoied = (itemMethod.id === method.id) ? true : false 
        })

        setAcceptExternalPaymentMethods(a);

    }

    const InternationalTransferHelpLink = (
        props: {paymentMethodSymbol: string}
    ) => {

        const paymentMethodSymbol = props.paymentMethodSymbol;
        let articleLink: string | null = null;

        if(paymentMethodSymbol === 'Airtel_C_Brazza') {
            articleLink = 'https://medium.com/@cryptofiat1022/comment-transférer-de-largent-depuis-airtel-congo-brazzaville-vers-mtn-cameroun-ou-orange-d15a43b394d9';
        }

        else if(paymentMethodSymbol === 'Airtel_Gabon') {
            articleLink = 'https://medium.com/@cryptofiat1022/comment-transférer-de-largent-depuis-airtel-money-gabon-vers-mtn-cameroun-ou-orange-cameroun-14c1f0e623ce';
        }

        else if(paymentMethodSymbol === 'MOOV_Gabon') {
            articleLink = 'https://medium.com/@cryptofiat1022/comment-transférer-de-largent-depuis-moov-money-gabon-vers-mtn-cameroun-ou-orange-cameroun-8a43055bc76d';
        }

        else if(paymentMethodSymbol === 'Airtel_Tchad') {
            articleLink = 'https://medium.com/@cryptofiat1022/comment-transférer-de-largent-depuis-airtel-money-tchad-vers-mtn-cameroun-ou-orange-cameroun-c90c1762e77c';
        }

        else if(paymentMethodSymbol === 'MOOV_Tchad') {
            articleLink = 'https://medium.com/@cryptofiat1022/comment-transférer-de-largent-depuis-moov-tchad-vers-mtn-cameroun-ou-orange-cameroun-4e958f0c9172';
        }

        else if(paymentMethodSymbol === 'MTN_C_Brazza') {
            articleLink = 'https://medium.com/@cryptofiat1022/comment-transférer-de-largent-depuis-mtn-congo-brazzaville-vers-mtn-cameroun-ou-orange-cameroun-6954cbc87e25';
        }


        if(!articleLink) return (
            <Typography sx={{mt: 1}}>
                <Typography variant='caption' sx={{mr: 1}}>
                    Vous ne savez pas comment transférer à l'international ?
                </Typography>
                <HashLink to="/about#contact-us" style={{fontSize: 'small'}}>
                    Contactez-nous
                </HashLink>
            </Typography>
        )

        return (

            <Alert severity="info" sx={{mt:1, textAlign:'left'}}>
                
                <Typography variant='caption' sx={{mr: 1}}>
                    Vous ne savez pas comment transférer avec <b>{ paymentMethod.name }</b> à l'international ?
                </Typography>
                <a href={articleLink} target='_blank' style={{fontSize: 'small'}}>
                    Trouvez de l'aide ici
                </a>

            </Alert>
        )

    }


    useEffect(() => {
        
        if(
            paymentMethod 
            && (paymentMethod.alertMessageForBuy 
            && (paymentMethod.alertMessageForBuy.length !== 0)) 
            && (orderBasicInfos.action === 'buy')
            && (orderBasicInfos.status === 'waiting_payment')
        ) {
            
            basicDatas.dialogManager({
                dialogTitle: '',
                dialogText: (
                    <Box sx={{
                        textAlign: 'center'
                    }}>

                        <WarningAmberIcon
                                color='warning'
                                sx={{
                                    margin: 'auto',
                                    fontSize: 70,
                                    mb: 2
                                }} />

                        <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                            Alerte de risque
                        </Typography>

                        <Typography
                            sx={{mb: 3}}
                        >
                            { paymentMethod.alertMessageForBuy }
                        </Typography>

                        <InternationalTransferHelpLink paymentMethodSymbol={paymentMethod.symbol} />

                    </Box>
                ),
                dialogAgreeBtnColor: 'error',
                dalogCloseBtnText: '',
                dialogAgreeBtnText: 'Je comprends le risque'
              }, () => {})
        }

    }, [])
    
    if((orderBasicInfos.status !== 'waiting_payment') && (orderBasicInfos.status !== 'marked_paid'))
    {
        return (
            <>
                <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
                <AdminMessage order={orderData}/>
            </>
        )
    }
    

    return (
        <div>

            <Stack direction="row" 
                    spacing={2} justifyContent='center' 
                    alignItems='center'
                    sx={{
                        mb: 4
                    }}>
                
                <Avatar src={paymentMethod.logo}
                        sx={{
                            width: isPC ? '60px' : '35px',
                            height: isPC ? '60px' : '35px'
                        }} />

                <ArrowRightAltIcon sx={{
                                      fontSize: isPC ? '50px' : '30px', 
                                      opacity: 0.6, 
                                      ml:2, mr:2
                                    }} />

                <AvatarGroup total={acceptExternalPaymentMethods && acceptExternalPaymentMethods.length} sx={{mb:3}}>

                    {

                        acceptExternalPaymentMethods.map((internalPaymentMethod: paymentMethodCheckableType) => {
                            return (
                                <Avatar src={internalPaymentMethod.logo} 
                                        alt={internalPaymentMethod.name} 
                                        key={internalPaymentMethod.id}
                                        sx={{
                                            width: isPC ? '60px' : '35px',
                                            height: isPC ? '60px' : '35px'
                                        }} />
                            )
                        })
                    }
        
                </AvatarGroup>

            </Stack>
            
            <Typography variant='h5'>{ paymentMethod.name }</Typography>

            <br /> 

            {
                acceptExternalPaymentMethods.map((internalPaymentMethod: paymentMethodCheckableType) => {
                    return (
                        <div style={{
                            marginBottom: '5px'
                        }}>
                            <Tooltip title="Cliquez pour copier le numéro">
                                <Chip
                                        label={<>{internalPaymentMethod.address} <span className='text-muted' style={{fontSize:'small'}}>{internalPaymentMethod.name}</span></>}
                                        avatar={
                                            !internalPaymentMethod.cpoied 
                                            ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                            : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                                        }
                                        variant="outlined"
                                        sx={{fontWeight:'bold', fontSize:'20px', marginTop:'10px', cursor:'pointer'}}
                                        onClick={() => copyNumber(internalPaymentMethod) }
                                    />
                            </Tooltip>
                        </div>
                    )
                })
            }

            <Alert
                severity="warning"
                variant='standard'
            >
                <Typography variant='caption'>
                    Veuillez copier le numéro sans ajouter l'identifiant du pays sinon le transfert échouera.
                </Typography>
            </Alert>


            <div style={{fontWeight:'bold', marginTop:'15px', fontSize:'25px'}}>
                { readabilityNumber(orderData.basic.fiatQte) + " FCFA"}
            </div>

            {
                orderBasicInfos.networkFee &&

                <TableContainer component={Paper}  sx={{mt:4}}>
                    <Table aria-label="customized table">

                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Quantité crypto
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => copyClipboard(orderBasicInfos.cryptoQte + '', "Montant copié !")}
                                    >
                                    <b>{ readabilityNumber(orderBasicInfos.cryptoQte) + " " + cryptoCurrency.symbol}</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Frais réseau

                                    <InfoPopup basicDatas={basicDatas}
                                                title="Pourquoi les frais de réseau ?"
                                                text={
                                                    <div>
                                                        Ces frais sont dus au fait que les frais de gas sur le réseau { network.symbol + " (" + network.name + ")" } sont élévés. Alors l'utilisateur paye une partie et CRYPTO-FIAT prend en charge le reste.

                                                        <br /> <br />

                                                        <div style={{textAlign: 'right'}} className='small'>
                                                            <a href="https://academy.binance.com/fr/articles/what-are-blockchain-transaction-fees" target={'_blank'}>
                                                                C'est quoi les frais de gas ?
                                                            </a>
                                                        </div>
                                                    </div>
                                                }>
                                        <span   className='small text-primary'
                                                style={{
                                                    marginLeft: '20px',
                                                    fontStyle: 'oblique',
                                                    cursor: 'pointer',
                                                    float: 'right'
                                                }}> 
                                                    Pourquoi ces frais ?
                                        </span>
                                    </InfoPopup>

                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{ readabilityNumber(orderBasicInfos?.networkFee) + " " + cryptoCurrency.symbol }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Montant net à recevoir
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{ amountReceived +  " " + cryptoCurrency.symbol }</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                ((orderBasicInfos.status === 'waiting_payment') && (!location.search.match('recap'))) &&

                <Alert severity="warning" sx={{marginTop:'30px', marginBottom:'20px', textAlign:'left'}}>
                    
                    <AlertTitle style={{fontWeight:'bold'}}>Geste important</AlertTitle>
                        Votre ordre a été créé avec succès. Mais avant de procéder au paiement, nous vous invitons à jetter un dernier coup d'oeil sur le 
                    <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link text-primary"> récapitulatif</Link>.

                    <br />

                    <div style={{textAlign:'right', marginTop:'10px'}}>
                        <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link">
                            <Button variant='contained' color='inherit'>
                                Voir le récapitulatif
                            </Button>
                        </Link>
                    </div>

                </Alert>
            }

            <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />

            <ListInstructions
                instrctions={[
                    {
                        step: 1,
                        title: "Effectuez le paiement",
                        content: (
                            <>
                                Envoyez <b>{ readabilityNumber(orderData.basic.fiatQte) + " FCFA" }</b> par <b>{paymentMethod.name + " "}</b>
                                à l'un des numéros suivants : 
                                {
                                    acceptExternalPaymentMethods.map((internalPaymentMethod: paymentMethodType, index) => {
                                        return (
                                            <span style={{
                                                marginLeft: '5px', marginRight: '5px'
                                            }}>
                                                <b>{internalPaymentMethod.address}</b> <b style={{fontStyle:'oblique', fontSize:'small'}}>({internalPaymentMethod.name})</b> {index === 0 ? " ou " : ''}
                                            </span>
                                        )
                                    })
                                } 

                                en choisissant le Cameroun comme pays de destination.

                                <Alert
                                    severity="warning"
                                    variant='standard'
                                    sx={{mt: 2}}
                                >
                                    <Typography variant='caption'>
                                        Veuillez ne pas ajouter l'identifiant du pays sur le numéro sinon le transfert échouera.
                                    </Typography>
                                </Alert>

                                <InternationalTransferHelpLink paymentMethodSymbol={paymentMethod.symbol} />
                            </>
                        )
                    },

                    {
                        step: 2,
                        title: "Marquez l'ordre comme étant payé",
                        content: (
                            <>
                                Cliquez sur <b>MARQUER PAYÉ</b> en bas de page. <br />
                                {   orderBasicInfos.status === 'waiting_payment' && 
                                    <Typography variant='caption' color='error'>Si vous ne marquez pas comme étant payé, l'ordre ne sera pas traité et finira par être annulé pour delai dépassé.</Typography>
                                }
                            </>
                        )
                    }
                ]}

                receptionAddressNote={
                    <>
                        Lorsque nous recevons votre paiement, vous recevrez <b className='cursor-pointer' onClick={() => copyClipboard(amountReceived as string, "La quantité a été copiée !")}>{ amountReceived + " " + cryptoCurrency.symbol + " " }</b>
                        dans votre portefeuille crypto à l'adresse <b className='cursor-pointer' onClick={() => copyClipboard(orderData.basic.blockchainAddress, "L'adresse a été copié dans le presse-paier.")}>{orderData.basic.blockchainAddress}</b>
                        {orderBasicInfos.memo && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.memo ? orderBasicInfos.memo : '', "Le memo a été copié dans le presse-paier.")}>{ orderBasicInfos.memo }</b></span>} sur le réseau <b>{ network.symbol }</b>.
                    </>
                }
                note={
                    <>
                        <b>Note :</b> Veuillez noter que si nous ne recevons pas votre paiement dans un delai de <b>{ paymentMethod.maxTimePaymentLimit + ' minutes' }</b> à partir du moment où l'ordre a été créé, il sera automatiquent annulé.
                    </>
                }
            />

            <br />

            {
                orderBasicInfos.status === 'waiting_payment' &&

                <>
                    <Button type='button' variant='contained' startIcon={<DoneAllIcon />} onClick={props.confirmPayment}>
                        MARQUER PAYÉ
                    </Button>

                    <br /> <br /> <br /> <br />

                    <Button type='button' variant='contained' color='inherit' startIcon={<CancelIcon fontSize='small' color='error'/>} onClick={props.cancelOrder}>
                        Annuler l'ordre
                    </Button>
                </>
            }

            
        </div>
    )
}
