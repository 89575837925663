import { Box, Button, TextField, Typography } from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import { basicProps } from '../../utils/interfaces'
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';


export default function Test(props: basicProps) {

    const emailEditorRef = useRef<EditorRef>(null);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
    });
  };

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    console.log(unlayer)
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };
    

    return (
        <div>

            <br /><br /><br />


            <Box    
                sx={{
                    boxShadow: 2,
                    p: 3
                }}
            >


                <EmailEditor ref={emailEditorRef} onReady={onReady} />


                <Button 
                    type='submit'
                    variant='contained' 
                    fullWidth
                    onClick={exportHtml}
                >
                    Send
                </Button>

            </Box>


        </div>
    )
}
