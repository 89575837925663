import React from 'react'
import { Typography, Box, Avatar, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { basicProps } from '../../../utils/interfaces';
import { isPC, publicPath } from '../../../functions/basicFunctions';

interface airdropListType {
    title: string,
    description: string,
    bannerImg: string,
    buttonText: string,
    url: string,
    openNewTab: boolean
}


const airdropList: airdropListType[] = [
    {
       title: "CFT Airdrop",
       description: "Personnes ayant effectué au moins une transaction terminée avant le 20 décembre 2022.",
       bannerImg: publicPath + "images/cft-ardrop-banner.png",
       buttonText: 'Réclamer',
       url: "/cft-token/claim-airdrop/",
       openNewTab: false
    },

    {
        title: "Prime d'Inscription",
        description: "Faites vérifier votre compte et obtenez 1000 XAF gratuit.",
        bannerImg: publicPath + "images/kyc_airdrop_page_banner.png",
        buttonText: 'Vérifier le compte',
        url: "/kyc/",
        openNewTab: false
    },

    {
        title: "Prime d'Invitation",
        description: "Invitez vos amis sur CRYPTO-FIAT. Dès qu'un amis vérifie son compte, vous obtenez 500 XAF de bonus.",
        bannerImg: publicPath + "images/kyc_invitation_airdrop_page_banner.png",
        buttonText: 'Inviter des amis',
        url: "/sponsorships/",
        openNewTab: false
    }
]

export default function Airdrops(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <div style={{paddingTop:'50px', maxWidth:'600px', margin:'auto'}}>
            
            <Typography 
                variant='h5' 
                sx={{
                    mb:5, 
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}>
                { basicDatas.appName + " Airdrops"}
            </Typography>

            <Box sx={{
                display: 'grid',
                gridTemplateColumns: isPC ? "repeat(2, 1fr)" : '',
                gap: 4
            }}>

                {
                    airdropList.map((item, i) => {
                        return (

                            <Box 
                                key={i}
                                sx={{
                                    boxShadow: 1, 
                                    p: 2, 
                                    textAlign: 'center',
                                    borderRadius: 2,
                                    background: "linear-gradient(142deg, rgba(25,118,210,0.23719595259978987) 0%, rgba(0,0,0,0) 88%)"
                                }}>
                                
                                <Avatar src={ item.bannerImg }
                                        sx={{
                                            width: '100%',
                                            height: '100px',
                                            borderRadius: '5px',
                                        }} />

                                
                                <Typography 
                                            variant='h6'
                                            color="GrayText"
                                            sx={{
                                                mt: 2,
                                                mb: 2,
                                                fontWeight: 'bold'
                                            }}>
                                    {item.title}
                                </Typography>
                                
                                <Typography sx={{mb: 2}}>
                                    <Typography 
                                        variant='caption'
                                        color="GrayText"
                                        sx={{
                                            mt: 2,
                                            mb: 2
                                        }}>
                                        { item.description }
                                    </Typography>
                                </Typography>

                                <Link to={item.url} target={item.openNewTab ? '_blank' : '_self'}>
                                    <Button 
                                        variant='contained' 
                                        fullWidth
                                        sx={{
                                            borderRadius: 20,
                                            backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'
                                        }}
                                        >
                                        { item.buttonText }
                                    </Button>
                                </Link>
                            </Box>

                        )
                    })
                }

            </Box>

        </div>
    )
}
