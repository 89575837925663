import { useEffect, useState } from 'react'
import { basicProps, orderType } from '../../utils/interfaces';
import { 
    Typography, Box, ToggleButtonGroup, ToggleButton, 
    List, ListItem, Button, Avatar, 
    ListItemAvatar, ListItemText, Chip, Divider,
    TextField, Tooltip, Badge, Stack,
    Alert,
    AlertTitle
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axiosApi from '../../functions/axiosApi';
import moment from 'moment';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { copyClipboard, isPC, readabilityNumber } from '../../functions/basicFunctions';
import QueryString from 'qs';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import OrderStatusLabel from '../../components/OrderStatusLabel';
import InfoPopup from '../../components/InfoPopup';
import OrderTypeBadge from '../../components/OrderTypeBadge';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MessageIcon from '@mui/icons-material/Message';
import LatestOrderAlert from './LatestOrderAlert';

interface useruserTotalTransactionsInfosType {
    all_count: number,
    waiting_payment: number,
    marked_paid: number,
    canceled_by_user: number,
    canceled_by_admin: number,
    completed: number,
    totalTransactionVolume: number
}

export default function ListOrders(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [orders, setOrders] = useState<orderType[]>([]);
    const [totalTransactionsInfos, setTotalTransactionsInfos] = useState<useruserTotalTransactionsInfosType>();
    const [status, setStatus] = useState('all');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [latestOrder, setLatestOrder] = useState<orderType>()

    useEffect(() => {
        getOrders();
    }, [status, queryValue])

    useEffect(() => {
        if(orders[0]) setLatestOrder(orders[0])
    }, [orders])
    

    const getOrders = () => {

        setIsLoading(true);

        axiosApi.post("orders/get-orders.php", QueryString.stringify({status, offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newOrdersArray = orders.concat(response.data.orders);
                setOrders(newOrdersArray);
                setOffset(offset + 5);
                setTotalTransactionsInfos(response.data.totalTransactionsInfos);

                if(response.data.orders.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }
    
    return (
        <div style={{maxWidth:'700px', margin:'auto'}}>
            
            <br />

            <Typography variant={isPC ? "h4" : 'h5'} className='text-center'>
                Mes ordres
            </Typography>

            <br /> 

            <Box sx={{mb: 5, textAlign: 'center'}}>

                <InfoPopup basicDatas={basicDatas}
                            title="Volume total de transactions"
                            text={
                                <div>
                                    Il s'agit de l'équivalent en FCFA de la somme totale de valeurs échangées dans tous vos ordres avec le statut <span style={{fontStyle: 'oblique'}}>Transaction Terminée</span>. 
                                    <br /><br />
                                    Votre volume total transactions en FCFA est de : <br /> 
                                    <b>{ readabilityNumber(totalTransactionsInfos?.totalTransactionVolume + '')}</b>
                                </div>
                            }>
                    <Tooltip title="Cliquez pour en savoir plus">
                        <Chip
                                label={<span className='text-muted' style={{fontSize:'small'}}>Volume total : <b>{ readabilityNumber(totalTransactionsInfos?.totalTransactionVolume + '') + ' XAF'}</b></span>}
                                avatar={<InfoIcon sx={{fontSize:'small'}} />}
                                variant="outlined"
                                sx={{ fontWeight:'bold', fontSize:'20px', marginTop:'10px', cursor:'pointer' }}
                            />
                    </Tooltip>
                </InfoPopup>
                
            </Box>

            <div className="text-center">
                <ToggleButtonGroup
                    color="standard"
                    value={status}
                    exclusive
                    onChange={
                        (e, newStatus) => {
                            setStatus(newStatus);
                            setOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    sx={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                    aria-label="order-status"
                    >

                    
                    <ToggleButton 
                        value="all" 
                        sx={{
                            minWidth: '150px',
                            border: 'none'
                        }}
                    >

                        <Typography
                            sx={{
                                backgroundColor: status === 'all' ? 'var(--site-color)' : 'rgba(167, 202, 237, 0.2)',
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: status === 'all' ? 'white' : 'var(--site-color)',
                                borderRadius: 10,
                                textTransform: 'none'
                            }}
                        >
                            Tout ({totalTransactionsInfos ? totalTransactionsInfos.all_count : 0})
                        </Typography>
                    </ToggleButton>

                    <ToggleButton 
                        value="waiting_payment" 
                        sx={{
                            minWidth: '300px',
                            border: 'none'
                        }}
                    >

                        <Typography
                            sx={{
                                backgroundColor: status === 'waiting_payment' ? 'var(--site-color)' : 'rgba(167, 202, 237, 0.2)',
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: status === 'waiting_payment' ? 'white' : 'var(--site-color)',
                                borderRadius: 10
                            }}
                        >
                            <Badge 
                                max={9999999999999} badgeContent={totalTransactionsInfos ? totalTransactionsInfos.waiting_payment : 0} 
                                color="primary"
                                sx={{textTransform: 'none'}}
                            >
                                En attente paiement
                            </Badge>
                        </Typography>
                    </ToggleButton>

                    <ToggleButton 
                        value="marked_paid" 
                        sx={{
                            minWidth: '210px',
                            border: 'none'
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: status === 'marked_paid' ? 'var(--site-color)' : 'rgba(167, 202, 237, 0.2)',
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: status === 'marked_paid' ? 'white' : 'var(--site-color)',
                                borderRadius: 10
                            }}
                        >
                            <Badge 
                                max={9999999999999} badgeContent={totalTransactionsInfos ? totalTransactionsInfos.marked_paid : 0} 
                                color="primary"
                                sx={{textTransform: 'none'}}
                            >
                                Traitement
                            </Badge>
                        </Typography>
                    </ToggleButton>

                </ToggleButtonGroup>

                <ToggleButtonGroup
                    color="primary"
                    value={status}
                    exclusive
                    onChange={
                        (e, newStatus) => {
                            setStatus(newStatus);
                            setOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    sx={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                    aria-label="order-status"
                    >

                    <ToggleButton 
                        value="completed"
                        sx={{
                            minWidth: '170px',
                            border: 'none'
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: status === 'completed' ? 'var(--site-color)' : 'rgba(167, 202, 237, 0.2)',
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: status === 'completed' ? 'white' : 'var(--site-color)',
                                borderRadius: 10
                            }}
                        >
                            <Badge 
                                max={9999999999999} badgeContent={totalTransactionsInfos ? totalTransactionsInfos.completed : 0} 
                                color="success"
                                sx={{textTransform: 'none'}}
                            >
                                Terminé
                            </Badge>
                        </Typography>
                    </ToggleButton>

                    <ToggleButton 
                        value="canceled_by_user"
                        sx={{
                            minWidth: '250px',
                            border: 'none'
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: status === 'canceled_by_user' ? 'var(--site-color)' : 'rgba(167, 202, 237, 0.2)',
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: status === 'canceled_by_user' ? 'white' : 'var(--site-color)',
                                borderRadius: 10
                            }}
                        >
                            <Badge 
                                max={9999999999999} badgeContent={totalTransactionsInfos ? totalTransactionsInfos.canceled_by_user : 0} 
                                color="error"
                                sx={{textTransform: 'none'}}
                            >
                                Annulé par user
                            </Badge>
                        </Typography>
                    </ToggleButton>

                    <ToggleButton 
                        value="canceled_by_admin"
                        sx={{
                            minWidth: '250px',
                            border: 'none'
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: status === 'canceled_by_admin' ? 'var(--site-color)' : 'rgba(167, 202, 237, 0.2)',
                                p: 1,
                                pl: 2,
                                pr: 2,
                                color: status === 'canceled_by_admin' ? 'white' : 'var(--site-color)',
                                borderRadius: 10
                            }}
                        >
                            <Badge 
                                max={9999999999999} badgeContent={totalTransactionsInfos ? totalTransactionsInfos.canceled_by_admin : 0} 
                                color="error"
                                sx={{textTransform: 'none'}}
                            >
                                Annulé par admin
                            </Badge>
                        </Typography>
                    </ToggleButton>

                </ToggleButtonGroup>

                <br /> <br />

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un ordre : ID" 
                    variant="outlined"
                    placeholder="Entrez l'ID de l'ordre"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            {
                ((queryValue.length !== 0) && (orders.length === 0)) &&
                <Typography className='text-center mt-4'>
                    Aucun ID d'ordre correspondant à <b>{queryValue}</b>
                </Typography>
            }

            {
                ((orders.length === 0) && !isloading) &&
                <div className='text-center'>

                    <Typography className='text-center mt-5 small text-muted' sx={{mb:4}}>
                        Aucun ordre
                    </Typography>

                    <Link to="/trade/buy/" className='link'>
                        <Button variant='contained' fullWidth={isPC ? false : true} >
                            Acheter crypto
                        </Button>
                    </Link>

                    <Link to="/trade/sell/" className='link'>
                        <Button variant='contained' 
                                sx={{
                                marginLeft: isPC ? '30px' : '0px',
                                marginTop: isPC ? '0px' : '20px'
                                }} 
                                color='error'
                                fullWidth={isPC ? false : true} >
                            Vendre crypto
                        </Button>
                    </Link>
                </div>
                
            }

            <br />


            {
                latestOrder && 

                <LatestOrderAlert latestOrder={latestOrder} />

            }

            <List sx={{ bgcolor: 'background.paper', margin:'auto' }}>
                
                {
                    orders.map((order: orderType, orderIndex) => {

                        const basicInfos = order.basic;
                        const action = basicInfos.action;
                        const paymentMethod = order.paymentMethod;
                        const cryptoCurrency = order.cryptoCurrency;
                        const network = order.network;

                        const sendInfos = order.sendInfos;
                        const sendCurrency = sendInfos.sendCurrency;
                        const sendNetwork = sendInfos.sendNetwork;
                        const sendQte = sendInfos.sendQte;

                        if(!cryptoCurrency) return (
                            <div>
                                Actif non listé !
                                <Divider variant="inset" component="li" sx={{mt:2, mb:2}} />
                            </div>
                        )

                        if (action === 'swap') return (
                            <Box key={orderIndex}>

                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="text.primary"
                                    sx={{fontWeight:'bold', mb: 1}}
                                >
                                    <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                        <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.orderID, "L'ID de l'ordre a été copié !")}>{"ID : " + basicInfos.orderID}</span>
                                    </Tooltip>
                                </Typography>

                                <Typography sx={{mb: 1}}>
                                    <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                        <OrderTypeBadge type={basicInfos?.action} /> 
                                    </Link>
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                </Typography>

                                <br />

                                {
                                    basicInfos.status === 'waiting_payment' &&
                                
                                    <Typography>

                                        Delai paiement :

                                        <Typography variant='caption' sx={{fontWeight: 'bold', color: '#D32F2F'}}>
                                            {
                                                sendNetwork.maxTimePaymentLimit + ' minutes'
                                            }
                                        </Typography>
                                        
                                    </Typography>
                                }

                                <Typography variant='caption'>{moment(basicInfos.date).fromNow()}</Typography>
                
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                >

                                    <List>

                                        <ListItem alignItems="flex-start" sx={{p: 0}}>

                                            <ListItemAvatar>
                                                <Avatar 
                                                    alt={sendCurrency.symbol} 
                                                    src={sendCurrency.logo}
                                                    sx={{
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={<b>{sendCurrency.symbol}</b>}
                                                secondary={
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                            color="text.primary"
                                                        >
                                                            { sendNetwork.symbol }
                                                        </Typography>

                                                        <Typography>
                                                            { sendQte }
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>

                                    </List>

                                    <ArrowRightAltIcon color='primary' sx={{fontSize: 40, opacity: 0.7}} />

                                    <List>

                                        <ListItem alignItems="flex-start" sx={{p: 0}}>

                                            <ListItemAvatar>
                                                <Avatar 
                                                    alt={cryptoCurrency.symbol} 
                                                    src={cryptoCurrency.logo} 
                                                    sx={{
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={<b>{cryptoCurrency.symbol}</b>}
                                                secondary={
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                            color="text.primary"
                                                        >
                                                            { network.symbol }
                                                        </Typography>

                                                        <Typography>
                                                            { basicInfos.cryptoQte }
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>

                                    </List>
                                    
                                </Stack>

                                {
                                    basicInfos.status === 'canceled_by_admin' &&
                                    
                                    <Typography
                                        sx={{
                                            mb: 2
                                        }}
                                    >
                                        <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                            <Chip 
                                                label={basicInfos.message}
                                                icon={ <MessageIcon fontSize='small'/> }
                                                sx={{
                                                    bgcolor: '#FDEDED',
                                                    color: '#E5593C',
                                                    cursor: 'pointer',
                                                    pl: 0.8
                                                }}
                                            />
                                        </Link>
                                    </Typography>
                                }

                                <ListItem alignItems="flex-start" sx={{p: 0}}>

                                    <ListItemText
                                        secondary={
                                            <>

                                                <div style={{textAlign:'right'}}>

                                                    {
                                                        basicInfos.status === 'waiting_payment' &&  
                                                        <Link to={"/orders/" + basicInfos.orderID + "/reglement/"} className="link text-primary" style={{display:'inline-block'}}>
                                                            <Button variant='contained' sx={{mr: 2}}>
                                                                Payer
                                                            </Button>
                                                        </Link>
                                                    }

                                                    <Link to={"/orders/" + basicInfos.orderID + "/"} className="link text-primary" style={{display:'inline-block'}}>
                                                        <Button endIcon={<KeyboardDoubleArrowRightIcon fontSize='small' />}>
                                                            Accéder à l'ordre
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    />
                                </ListItem>
                                
                                <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                            </Box>
                        ) 
                        
                        
                        return (

                            <div key={orderIndex}>

                                <ListItem alignItems="flex-start" sx={{pl: 0}}>
                                    <ListItemAvatar>
                                        <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                            <Avatar alt={cryptoCurrency.symbol} src={cryptoCurrency.logo} />
                                        </Link>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>

                                                
                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{fontWeight:'bold'}}
                                                >
                                                    <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                                        <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.orderID, "L'ID de l'ordre a été copié !")}>{"ID : " + basicInfos.orderID}</span>
                                                    </Tooltip>
                                                </Typography>

                                                <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                        sx={{fontWeight:'bold'}}
                                                    >
                                                        {basicInfos.cryptoQte + " " + cryptoCurrency.symbol + "_" + network.symbol}
                                                    </Typography>

                                                    {" — "} 
                                                    
                                                    <OrderTypeBadge type={basicInfos?.action} /> 
                                                </Link>

                                                <div>{cryptoCurrency.name}</div>
                                                
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                                </Typography>
                                            </>
                                        }
                                        secondary={
                                            <>

                                                {
                                                    basicInfos.status === 'waiting_payment' &&
                                                
                                                    <Typography>

                                                        Delai paiement :

                                                        <Typography variant='caption' sx={{fontWeight: 'bold', color: '#D32F2F'}}>
                                                            {
                                                                basicInfos.action === 'buy' 
                                                                ?   ' ' + paymentMethod.maxTimePaymentLimit + ' minutes' 
                                                                :   ' ' + network.maxTimePaymentLimit + ' minutes'
                                                            }
                                                        </Typography>
                                                        
                                                    </Typography>
                                                }

                                                <Typography variant='caption'>{moment(basicInfos.date).fromNow()}</Typography>
                                                
                                                {
                                                    basicInfos.status === 'canceled_by_admin' &&
                                                    
                                                    <Typography
                                                        sx={{
                                                            mt: 1,
                                                            mb: 2
                                                        }}
                                                    >
                                                        <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                                            <Chip 
                                                                label={basicInfos.message}
                                                                icon={ <MessageIcon fontSize='small' /> }
                                                                sx={{
                                                                    bgcolor: '#FDEDED',
                                                                    color: '#E5593C',
                                                                    cursor: 'pointer',
                                                                    pl: 0.8
                                                                }}
                                                            />
                                                        </Link>
                                                    </Typography>
                                                }

                                                <div style={{textAlign:'right'}}>

                                                    {
                                                        basicInfos.status === 'waiting_payment' &&  
                                                        <Link to={"/orders/" + basicInfos.orderID + "/reglement/"} className="link text-primary" style={{display:'inline-block'}}>
                                                            <Button variant='contained' sx={{mr: 2}}>
                                                                Payer
                                                            </Button>
                                                        </Link>
                                                    }

                                                    <Link to={"/orders/" + basicInfos.orderID + "/"} className="link text-primary" style={{display:'inline-block'}}>
                                                        <Button endIcon={<KeyboardDoubleArrowRightIcon fontSize='small' />}>
                                                            Accéder à l'ordre
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    />
                                </ListItem>
                                
                                <Divider variant="inset" component="li" sx={{mt:2, mb:4}} />

                            </div>
                        )
                    })
                }
            </List>
            
            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getOrders()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </div>
    )
}
