import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import QueryString from 'qs';
import { basicProps, currencyType, internalTransferType } from '../../../utils/interfaces';
import { Box, Avatar, Typography, Button, TextField } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PreviewTransfer from '../../../components/internal-transfers/PreviewTransfer';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { cftTokenInfo, readabilityNumber } from '../../../functions/basicFunctions';
import axiosApi from '../../../functions/axiosApi';

interface assetType extends currencyType {
    userBalance: number
}

export default function Bonus(props: basicProps) {

    const assetSymbol = cftTokenInfo.symbol;
    const basicDatas = props.basicDatas;

    const [asset, setAsset] = useState<assetType>();
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);

    const [transactions, setTransactions] = useState([]);
    

    useEffect(() => {

        axiosApi.post("wallet/get-asset-info.php", QueryString.stringify({ symbol: assetSymbol}))
        .then(response => {

            if(response.data.status === 'success'){
                setAsset(response.data.asset);
            }

        })

        getTransactions()

    }, [])

    const getTransactions = () => {

        setIsLoading(true);

        axiosApi.post("wallet/transfer/get-transactions.php", QueryString.stringify({offset, limit: 5, type: ['bonus', 'rewards']}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newtransactionsArray = transactions.concat(response.data.transactions);
                setTransactions(newtransactionsArray);
                setOffset(offset + 5);

                if(response.data.transactions.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    if(!asset) return (
        <Box sx={{
            textAlign: 'center',
            mt: 5
        }}>
            Chargement...
        </Box>
    )
    
    return (
        <Box sx={{
            maxWidth: 450,
            margin: 'auto',
            pt: 5
        }}>

            <Typography variant='h6' sx={{mb: 3, textAlign: 'center', fontWeight: 'bold'}}>
                Bonus reçus
            </Typography>

            <Box sx={{mt: 5}}>


                {
                    transactions.length === 0 &&
                    <Typography sx={{textAlign: 'center', mb: 3}}>

                        <Typography>
                            Vous n'avez pas encore reçu de bonus
                        </Typography>

                        <Typography variant='caption'>
                            Lorsque vous recevrez des bonus, vous pourrez les voir ici.
                        </Typography> 
                    </Typography>
                }

                {
                    transactions.map((transaction: internalTransferType) => {
                        return (
                            <PreviewTransfer 
                                        basicDatas={basicDatas}
                                        transaction={transaction} 
                                        currentUser={basicDatas.authUser}
                                        key={transaction.transactionID} />
                        )
                    })
                }

                <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getTransactions()}>
                            Afficher plus
                        </Button>
                }

                </div>

            </Box>
             
        </Box>
    )
}
