import { useEffect, useState } from 'react';
import { 
    Alert, AlertTitle, Avatar,
    TableContainer, Table, Paper, TableBody, TableRow, Box, 
    TextField, Button, Typography, List, ListItem, 
    ListItemIcon, ListItemText, Divider
} from '@mui/material';
import { basicProps, cftPartnerAccountStatus, currencyType, orderType } from '../../../utils/interfaces';
import ShowOrderStaus from './ShowOrderStaus';
import { cf_pay_info, cftTokenInfo, copyClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import InfoPopup from '../../../components/InfoPopup';
import AdminMessage from './AdminMessage';
import ShieldIcon from '@mui/icons-material/Shield';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

interface buyInstructionsProps extends basicProps {
    orderData: orderType;
    cancelOrder: () => void,
    setOrderData: (order:orderType) => void
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface assetType extends currencyType {
    userBalance: number,
    availableBalance: number
}

export default function BuyWithCFPAY(props: buyInstructionsProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const paymentMethod = orderData.paymentMethod;
    const cryptoCurrency = orderData.cryptoCurrency;
    const network = orderData.network;

    const [asset, setAsset] = useState<assetType>();

    const [feesAndCommissionsInfo, setFeesAndCommissionsInfo] = useState<{
        feesPercentage: number,
        transactionType: string,
        senderIsCftPartner: {status: cftPartnerAccountStatus},
        receiverIsCftPartner: {status: cftPartnerAccountStatus}
    }>();

    const fiatQteWithFees = (parseFloat(orderBasicInfos.fiatQte + '') + cf_pay_info.calcFees(orderBasicInfos.fiatQte, feesAndCommissionsInfo?.feesPercentage, feesAndCommissionsInfo?.transactionType));

    let amountReceived = orderBasicInfos.networkFee ? (parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(orderBasicInfos.networkFee + '')) : orderBasicInfos.cryptoQte;
    amountReceived = readabilityNumber(amountReceived);


    useEffect(() => {

        axiosApi.post("wallet/get-asset-info.php", QueryString.stringify({ symbol: 'CFT'}))
        .then(response => {

            if(response.data.status === 'success'){
                setAsset(response.data.asset);
            }

        })
        
        axiosApi.post("wallet/transfer/verify-receiver-account.php", QueryString.stringify({receiverID: cf_pay_info.issuerID}))
        .then(response => {
            if(response.data.status === 'success'){
                setFeesAndCommissionsInfo(response.data.feesAndCommissionsInfo);          
            }
        })

    }, [])
    

    const confirmPayment = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('orderID', orderData.basic.orderID);
        data.append('loaderText', "Vérification en cours...");

        basicDatas.dialogManager({
            dialogTitle: 'Confirmation de paiement (' + orderData.basic.orderID + ')',
            dialogText: (
                <Typography> 
                    En marquant votre ordre comme PAYÉ, vous déclarez avoir :

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon color='info' />
                            </ListItemIcon>

                            <ListItemText primary={`Vérifié le récapitulatif de votre ordre et êtes sûr de n'avoir commis aucune faute (surtout sur l'adresse de réception).`} />
                        
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon color='info' />
                            </ListItemIcon>

                            <ListItemText primary={`Compris que votre compte CF_PAY sera directement débité de ${ readabilityNumber(fiatQteWithFees) + " " + cftTokenInfo.symbol } lors de la validation de cet ordre.`} />
                      
                        </ListItem>
                    </List>

                    <Typography variant='caption'>
                        Gardez à l'esprit que { basicDatas.appName } n'a ni le droit, ni le possibilité de valider votre ordre si vous ne l'avez pas marqué comme PAYÉ. Mais si le delai de paiement est dépassé, l'ordre sera annulé.<br />
                        Vous ne pourrez plus annuler votre ordre s'il est déjà marqué comme PEYÉ.
                    </Typography>

                </Typography>
            ), 
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Confirmer"
          }, (response: string) => {
            
            if(response === 'ok') {
               
                axiosApi.post('orders/confirm-payment-cf-pay.php', data)
                .then(response => {
                    if(response.data.status === 'success'){
                        const orderDataUpdated = response.data.orderData;
                        props.setOrderData(orderDataUpdated);
                    }
                })
            }
      
        })

    }

    if((orderBasicInfos.status !== 'waiting_payment') && (orderBasicInfos.status !== 'marked_paid'))
    {
        return (
            <>
                <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
                <AdminMessage order={orderData}/>
            </>
        )
    }


    return (
        <div>
            
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'20px'}}>
                <Avatar src={paymentMethod.logo} 
                    sx={{
                        width:'100px', 
                        height:'100px',
                        boxShadow: 1
                    }} />
            </div>
            
            <b>{ paymentMethod.name }</b>

            <br /> 

            <div style={{fontWeight:'bold', marginTop:'15px', fontSize:'25px'}}>
                { readabilityNumber(orderData.basic.fiatQte) + " CFT"}
            </div>

            {
                orderBasicInfos.networkFee &&

                <TableContainer component={Paper}  sx={{mt:4}}>
                    <Table aria-label="customized table">

                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Quantité crypto
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => copyClipboard(orderBasicInfos.cryptoQte + '', "Montant copié !")}
                                    >
                                    <b>{ readabilityNumber(orderBasicInfos.cryptoQte) + " " + cryptoCurrency.symbol}</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Frais réseau

                                    <InfoPopup basicDatas={basicDatas}
                                                title="Pourquoi les frais de réseau ?"
                                                text={
                                                    <div>
                                                        Ces frais sont dus au fait que les frais de gas sur le réseau { network.symbol + " (" + network.name + ")" } sont élévés. Alors l'utilisateur paye une partie et CRYPTO-FIAT prend en charge le reste.

                                                        <br /> <br />

                                                        <div style={{textAlign: 'right'}} className='small'>
                                                            <a href="https://academy.binance.com/fr/articles/what-are-blockchain-transaction-fees" target={'_blank'}>
                                                                C'est quoi les frais de gas ?
                                                            </a>
                                                        </div>
                                                    </div>
                                                }>
                                        <span   className='small text-primary'
                                                style={{
                                                    marginLeft: '20px',
                                                    fontStyle: 'oblique',
                                                    cursor: 'pointer',
                                                    float: 'right'
                                                }}> 
                                                    Pourquoi ces frais ?
                                        </span>
                                    </InfoPopup>

                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{ readabilityNumber(orderBasicInfos?.networkFee) + " " + cryptoCurrency.symbol }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Montant net à recevoir
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{amountReceived +  " " + cryptoCurrency.symbol}</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                orderBasicInfos.status === 'waiting_payment' &&

                <>
                
                    <Alert severity="info" 
                        sx={{
                            marginTop:'30px', 
                            textAlign:'left'
                        }}>
                            
                        <AlertTitle style={{fontWeight:'bold'}}>Code PIN requis</AlertTitle>

                        Votre compte CF_PAY sera débité de <b>{ readabilityNumber(fiatQteWithFees) + " " + cftTokenInfo.symbol }</b> (frais : {cf_pay_info.calcFees(orderBasicInfos.fiatQte, feesAndCommissionsInfo?.feesPercentage, feesAndCommissionsInfo?.transactionType) + ' ' + cftTokenInfo.symbol}) lors de la validation de cet ordre. <br />
                        Pour confirmer le paiement, veuillez entrer votre code PIN.

                    </Alert>

                    <Box
                        component='form'
                        onSubmit={confirmPayment}
                        sx={{
                            maxWidth: 300,
                            margin: 'auto',
                            mt: 3,
                            boxShadow: 5,
                            p: 2,
                            borderRadius: 2
                        }}>

                        
                        <Box
                            sx={{
                                textAlign: 'center',
                                mb: 3
                            }}>
                            <ShieldIcon 
                                    color='info' 
                                    fontSize='large'
                                    />
                        </Box>

                        <Typography sx={{textAlign: 'left'}}>
                            <Typography variant='caption'>
                                Montant : <b>{ readabilityNumber(orderBasicInfos.fiatQte) + ' ' + cftTokenInfo.symbol }</b>
                            </Typography>
                        </Typography>

                        <Typography sx={{textAlign: 'left'}}>
                            <Typography variant='caption'>
                                Frais : <b>{ readabilityNumber(cf_pay_info.calcFees(orderBasicInfos.fiatQte, feesAndCommissionsInfo?.feesPercentage, feesAndCommissionsInfo?.transactionType)) + ' ' + cftTokenInfo.symbol }</b>
                            </Typography>
                        </Typography>

                        <Typography sx={{textAlign: 'left', mb: 2}}>
                            <Typography variant='caption'>
                                Total : <b>{  readabilityNumber(fiatQteWithFees) + ' ' + cftTokenInfo.symbol }</b>
                            </Typography>
                        </Typography>

                        {
                            asset &&

                            <>

                                <Divider sx={{maxWidth: '50%', margin: 'auto', mb: 2}} />
                                
                                <Typography sx={{textAlign: 'left', mb: 2}}>
                                    <Typography variant='caption'>
                                        Solde disponible : <Typography variant='caption' color='primary' fontWeight='bold'>{ readabilityNumber(parseFloat((asset.availableBalance).toFixed(5))) + ' ' + asset.symbol }</Typography>
                                    </Typography>
                                </Typography>
                            </>
                        }
                        

                        <TextField
                            type='password'
                            name='pinCode'
                            label='Code PIN'
                            placeholder='Entrez le code'
                            fullWidth
                            required
                            sx={{
                                mb: 2.5
                            }} />

                        <Button
                            type='submit'
                            variant='contained'
                            color='info'
                            fullWidth>
                            Confirmer
                        </Button>
                    </Box>

                </>
            }

            <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />

            <Alert severity="info" 
                    sx={{
                        marginTop:'30px', 
                        marginBottom:'20px', 
                        textAlign:'left',
                        background: 'white',
                        boxShadow: 2
                    }}>
                
                <AlertTitle style={{fontWeight:'bold'}}>Veuillez noter</AlertTitle>

                Votre compte sera automatiquement débité de <b>{ readabilityNumber(fiatQteWithFees) + ' ' + cftTokenInfo.symbol }</b> et vous recevrez <b className='cursor-pointer' onClick={() => copyClipboard(amountReceived as string, "La quantité a été copiée !")}>{ amountReceived + " " + cryptoCurrency.symbol + " " }</b>
                dans votre portefeuille crypto à l'adresse  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.blockchainAddress, "L'adresse a été copié dans le presse-paier.")}>{orderBasicInfos.blockchainAddress}</b> 
                {orderBasicInfos.memo && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.memo ? orderBasicInfos.memo : '', "Memo copié !")}>{ orderBasicInfos.memo }</b></span>} sur le réseau <b>{network.symbol}</b>.
                

                {
                    orderBasicInfos.status === 'waiting_payment' &&
                    <>
                        <br /> <br />
                        Gargez à l'esprit que si vous n'avez pas confirmé le paiement dans un délai de <b>{ paymentMethod.maxTimePaymentLimit + ' minutes' }</b> à partir du moment où l'ordre a été créé, il sera automatiquent annulé.
                    </>
                }
                
            
            </Alert>

            <br />

            {
                orderBasicInfos.status === 'waiting_payment' &&

                <>

                    <br /> <br /> 

                    <Button type='button' variant='contained' color='inherit' startIcon={<CancelIcon fontSize='small' color='error'/>} onClick={props.cancelOrder}>
                        Annuler l'ordre
                    </Button>
                </>

            }

        </div>
    )
}
