import { toast } from "react-toastify";
import { paymentMethodType } from "../utils/interfaces";

export function copyClipboard(text: string, successMessage?: string) {
    
    const textInput = document.getElementById('copy-to-clipboard-input') as HTMLInputElement;

    if(textInput){
        textInput.value = text;
        textInput.select();
        textInput.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(textInput.value).then(() => {
            toast.success(successMessage ? successMessage : "Le text a bien été copié dans le presse-papier.");
        }).catch(() => {
            toast.warn("Impossible de faire la copie !");
        })
    }
}

export const readClipboard = (callback: (content: string | null) => void) => {
    navigator.clipboard.readText().then(clipboardContent => {
        callback(clipboardContent)
    }).catch(() => {
        toast.warn("Impossible de lire dans le presse-papier !");
    })
    callback(null);
}

export const urlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());    
}

export const readabilityNumber =(nbr: number | string) => {
    
    var number = '' + nbr;
    number = number.replaceAll(',', '.');
    var result = '';
    var count = 0;

    let splitNumber = number.split('.');

    let firstPart = splitNumber[0];
    let secondPart = splitNumber[1] ? "." + splitNumber[1] : '';

    for(var i = firstPart.length - 1 ; i>=0 ; i--)
    {
        if((count != 0) && (count % 3 == 0))
            result = firstPart[i] + ' ' + result ;
        else {
            result = firstPart[i] + result ;
        }
        count++;
    }

    result = result + secondPart;
 
    return result;
}

export const permissionDescription = (name: string): {description: string, label: string} => {
        
    if(name === 'buy_sell_crypto') return {
        'label' : 'Achat et vente cryptos',
        description: "Créer un ordre d'achet ou de vente crypto."
    }

    if(name === 'internal_transfer') return {
        label: 'Transfers internes',
        description: "Transfers internes CF_PAY."
    }

    return {
        label: '',
        description: ''
    }
    
}



export const findPaymentMethod = (paymentMethodSymbol: string, paymentMethods: paymentMethodType[]) => {
    
    const matchedPaymentMethod = paymentMethods.filter((item) => item.symbol === paymentMethodSymbol);
    
    if(matchedPaymentMethod.length !== 0){
        return matchedPaymentMethod[0];
    }

    return 
}


export const isPC = window.screen.width >= 700;

export const cftTokenInfo = {
    name: 'CryptoFiat Token',
    symbol: 'CFT',
    blockchain: 'BSC (Binance Smart Chain)',
    network: 'BEP20 (Binance Smart Chain)',
    ownerAddress: '0x70248b60Ea1223D731Fd5d622d66Bea4C651d6A8',
    contractAddress: '0x9082cBA2B24B27A000844eadC64F52D1F3caE0A7'
};

export const cf_pay_info = {
    name: "CRYPRO-FIAT PAY",
    symbol: 'CF_PAY',
    issuerID: process.env.NODE_ENV === 'production' ? '99491f4eb8c6a0efbd9c' : '67df3dfbffe84121c44b',
    feePercentage: 1,
    feeReason: "Les frais de transactions sont utilisés pour rémunérer les vendeurs locaux afin qu'ils puissent vous permettre de recharger votre compte ou faire des retraits.",
    calcFees: (amount: number | string, feesPercentage: number | undefined = 0, transactionType: string = 'transfer') : number => {

        amount = typeof amount === 'string' ? parseFloat(amount) : amount;
        let feesAmount = amount > 0 ? amount * feesPercentage / 100 : 0;

        if((feesAmount > 0) && (transactionType !== 'payment')) {
            feesAmount = feesAmount >= 50 ? feesAmount : 50;
        }

        feesAmount = feesAmount <= 15000 ? feesAmount : 15000;
        feesAmount = feesPercentage === 0 ? 0 : feesAmount;
        
        return feesAmount;

    }
};


export const maxTextLength = (max: number, text?: string) => {

    if(!text) return '';

    const partLength = max / 2;

    if(text.length <= max) return text;
    
    return text.substring(0, partLength) + "..." + text.substring(text.length - partLength)
}

export const copyPhoneNumber = (phoneNumber: string): void => {
        
    let splitPhoneNumber = phoneNumber.split(' ');
    splitPhoneNumber.shift();
    const phoneNumberWithoutCode = splitPhoneNumber.join('');

    copyClipboard(phoneNumberWithoutCode, "Le numéro a été copié !");
}

export const binacePriceApiPath = "https://api.binance.com/api/v3/ticker/price?symbol=";
export const apiPath = process.env.NODE_ENV === 'production' ? "https://api.cryptofiat-ex.com/main/php/" : "http://localhost/crypto-fiat/api/main/php/";
export const publicPath = process.env.NODE_ENV === 'production' ? "https://cryptofiat-ex.com/" : "http://localhost/crypto-fiat/public/"