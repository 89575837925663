import { Alert, AlertTitle, Avatar, Box, Button, Chip, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import { useState } from 'react'
import { basicProps, orderType } from '../../../utils/interfaces';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ShowOrderStaus from './ShowOrderStaus';
import { Link, useLocation } from 'react-router-dom';
import { cftTokenInfo, copyClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import AdminMessage from './AdminMessage';
import MarkAsPaidBtn from './MarkAsPaidBtn';
import ListInstructions from './ListInstructions';
import QrCodeDisplay from '../../../components/QrCodeDisplay';

interface myProps extends basicProps {
    orderData: orderType;
    confirmPayment: () => void,
    cancelOrder: () => void
}

export default function SellWithCftInstructions(props: myProps) {

    const location = useLocation();
    const basicDatas = props.basicDatas;

    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const cryptoCurrency = orderData.cryptoCurrency;
    const network = orderData.network;

    const [addressCopied, setAddressCopied] = useState(false);
    const [memoCopied, setMemoCopied] = useState(false);

    if((orderBasicInfos.status !== 'waiting_payment') && (orderBasicInfos.status !== 'marked_paid'))
    {
        return (
            <>
                <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
                <AdminMessage order={orderData}/>
            </>
        )
    }

    return (
        <div>

            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'20px'}}>
                <QrCodeDisplay
                    text={network.address}
                    sx={{
                        width: 150,
                        height: 150,
                        borderRadius: 1,
                        boxShadow: 3
                    }}
                />
            </div>

            <Tooltip title="Cliquez pour copier l'adresse">
                <Chip
                        label={
                            <>
                                { 
                                    ((network.memoRequiredForBuy == 1) && network.memo) &&
                                
                                    <Typography variant='caption'>
                                        Adresse
                                    </Typography> 
                                }

                                { ' ' + network.address }
                            </>
                        }
                        avatar={
                            !addressCopied 
                            ? <ContentCopyIcon sx={{fontSize:'small'}} />
                            : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                        }
                        variant="outlined"
                        sx={{fontWeight:'bold', fontSize:'15px', marginTop:'10px', cursor:'pointer'}}
                        onClick={() => { copyClipboard(network.address, "Adresse copiée !"); setAddressCopied(true)}}
                    />
            </Tooltip>

            {
                ((network.memoRequiredForBuy == 1) && network.memo) && 

                <Typography sx={{
                    mt: 1
                }}>

                    <Tooltip title="Cliquez pour copier l'adresse">
                        <Chip
                                label={
                                    <>
                                        <Typography variant='caption'>
                                            Memo
                                        </Typography> 
                                        { ' ' + network.memo }
                                    </>
                                }
                                avatar={
                                    !memoCopied 
                                    ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                    : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                                }
                                variant="outlined"
                                sx={{fontWeight:'bold', fontSize:'15px', marginTop:'10px', cursor:'pointer'}}
                                onClick={() => {copyClipboard(network.memo ? network.memo : '', "Le memo a été copiée dans le presse-paier."); setMemoCopied(true)}}
                            />
                    </Tooltip>

                    <Alert severity='warning' sx={{mt: 1, width: 'auto'}}>
                        L'adresse et le memo sont tous deux nécessaires, sinon vous perdrez vos cryptos.
                    </Alert>

                </Typography>
            }

            <div style={{fontWeight:'bold', marginTop:'15px', fontSize:'25px'}}>
                { readabilityNumber(orderBasicInfos.cryptoQte)}
                <br />
                { cryptoCurrency.symbol + '_' + network.symbol }
                <Avatar src={cryptoCurrency.logo}
                        sx={{
                            display: 'inline-block',
                            ml: 1,
                            width: '23px',
                            height: '23px'
                        }} />
            </div>

            {
                ((orderBasicInfos.status === 'waiting_payment') && (!location.search.match('recap'))) &&

                <Alert severity="warning" sx={{marginTop:'30px', marginBottom:'20px', textAlign:'left'}}>
                    
                    <AlertTitle style={{fontWeight:'bold'}}>Geste important</AlertTitle>
                    Votre ordre a été créé avec succès. Mais avant de procéder au paiement, nous vous invitons à jetter un dernier coup d'oeil sur le 
                    <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link text-primary"> récapitulatif</Link>.

                    <br />

                    <div style={{textAlign:'right', marginTop:'10px'}}>
                        <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link">
                            <Button variant='contained' color='inherit'>
                                Voir le récapitulatif
                            </Button>
                        </Link>
                    </div>

                </Alert>
            }

            <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />


            <ListInstructions
                instrctions={[
                    {
                        step: 1,
                        title: "Effectuez le paiement",
                        content: (
                            <>
                                Envoyez <b>{ readabilityNumber(orderBasicInfos.cryptoQte) + " " + cryptoCurrency.symbol}</b> à l'adresse
                                <b>{" " + network.address}</b>
                                {((network.memoRequiredForSell == 1) && network.memo) && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(network.memo ? network.memo : '', "Le memo a été copié dans le presse-paier.")}>{ network.memo }</b></span>} sur le réseau <b>{network.symbol + " (" + network.name + ")"}</b>.
                            </>
                        )
                    },

                    {
                        step: 2,
                        title: "Marquez l'ordre comme étant payé",
                        content: (
                            <>
                                Cliquez sur <b>MARQUER PAYÉ</b> en bas de page. <br /> 
                                {   orderBasicInfos.status === 'waiting_payment' && 
                                    <Typography variant='caption' color='error'>Si vous ne marquez pas comme étant payé, l'ordre ne sera pas traité et finira par être annulé pour delai dépassé.</Typography>
                                }
                            </>
                        )
                    }
                ]}

                receptionAddressNote={
                    <>
                        Lorsque nous recevons vos fonds, vous recevrez <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.fiatQte as string, "La quantité a été copiée !")}>{ readabilityNumber(orderBasicInfos.fiatQte) + " " + cftTokenInfo.symbol + " "}</b>
                        dans votre portefeuille crypto à l'adresse  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.cftReceptionAddress, "L'adresse a été copié dans le presse-paier.")}>{ orderBasicInfos.cftReceptionAddress }</b> sur le réseau <b>{ cftTokenInfo.network }</b>.
                    </>
                }
                note={
                    <>
                        <b>Note :</b> Veuillez noter que si nous ne recevons pas votre paiement dans un delai de <b>{ network.maxTimePaymentLimit + ' minutes' }</b> à partir du moment où l'ordre a été créé, il sera automatiquent annulé.
                    </>
                }
            />

            <br />

            {
                orderBasicInfos.status === 'waiting_payment' &&

                <>
                    <MarkAsPaidBtn confirmPayment={props.confirmPayment} basicDatas={basicDatas} />

                    <br /> <br /> <br /> <br />

                    <Button type='button' variant='contained' color='inherit' startIcon={<CancelIcon fontSize='small' color='error'/>} onClick={props.cancelOrder}>
                        Annuler l'ordre
                    </Button>
                </>

            }

            
        </div>
    )
}
