import { Link, Route, Routes } from 'react-router-dom'
import { cftTokenInfo } from '../../../functions/basicFunctions'
import { basicProps } from '../../../utils/interfaces'
import CftAirdrop from './cft-token/CftAirdrop'
import Discounts from './discounts/Discounts'

export default function Airdrops(props: basicProps) {
    return (

        <div>

            <Routes>
                <Route path='/' element={
                    <div style={{textAlign:'center'}}>

                        <br /><br />
        
                        <Link to="/ctr/airdrops/cft-token/" className='link' style={{fontSize:'18px'}}> { cftTokenInfo.symbol + " token"} </Link>
                        <br /><br />

                        <Link to="/ctr/airdrops/discounts/" className='link' style={{fontSize:'18px'}}> Remises </Link>
                        <br /><br />
        
                    </div>
                } />

                <Route path='cft-token' element={<CftAirdrop { ...props } />} />
                <Route path='discounts/*' element={<Discounts { ...props } />} />
            </Routes>
            
        </div>
    )
}
