import axiosApi from '../../../functions/axiosApi'
import { basicProps, User } from '../../../utils/interfaces';
import { useEffect } from 'react';
import { 
    Button, Divider, List, Typography, Box
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';
import { readabilityNumber } from '../../../functions/basicFunctions';
import SponsorshipTransactionTypeBadge from '../../../pages/sponsorships/SponsorshipTransactionTypeBadge';


export default function WithdrawCommissions(props: basicProps) {

    const [demands, setDemands] = useState([]);
    const [numDemands, setNumDemands] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        getWithdrawalDemands();
    }, [])
    

    const getWithdrawalDemands = () => {

        setIsLoading(true);

        axiosApi.post("ctr/sponsorships/withdraw-demands.php", QueryString.stringify({
            offset, limit: 3
        }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDemandsArray = demands.concat(response.data.demands);
                setDemands(newDemandsArray);
                setNumDemands(response.data.numDemands);
                setIsLoading(false);
                setOffset(offset + 3);

                if(response.data.demands.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <div
            style={{
                maxWidth: 500,
                margin: 'auto'
            }}
        >
            
            <br />

            <Typography variant="h5" className='text-center' component="h5">
                Retraits commissions
                <br />
                <b style={{fontSize: '16px'}}>{ numDemands }</b>
            </Typography>

            <br /><br />


            <List sx={{ bgcolor: 'background.paper', margin:'auto' }}>

                {
                    demands.map((withdraw: any) => {

                        const user: User = withdraw.user;
                        const userCurrentBalance: {allTimeBalance: number, availableBalance: number} = withdraw.userCurrentBalance;

                        return (
                            <Box key={withdraw.id}>

                                <Typography
                                    component="div"
                                    variant="h6"
                                    color={withdraw.type === 'withdrawal' ? 'error' : 'primary'}
                                    sx={{fontWeight:'bold'}}
                                >
                                    {withdraw.type === 'withdrawal' ? '-' : '+'} {readabilityNumber(withdraw.amount) + " CFT"}
                                </Typography>

                                <Typography sx={{mb: 2}} color='primary'>
                                    <a href={"/ctr/users/" + user.user_id + '/'} target='_blank' style={{textDecoration: 'none'}}>
                                        { user.lastName + ' ' + user.firstName }
                                    </a>
                                </Typography>

                                <Typography sx={{fontSize: 'small', mb: 2}}>
                                    Balance All time : <b>{ readabilityNumber(userCurrentBalance.allTimeBalance) + ' CFT'}</b>  
                                    <br />
                                    Balance Disponible : <b>{ readabilityNumber(userCurrentBalance.availableBalance) + ' CFT'}</b>
                                </Typography>

                                <SponsorshipTransactionTypeBadge transactionType={withdraw.type} date={withdraw.date} />
                
                                <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                            </Box>
                        )
                    })
                }

            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getWithdrawalDemands()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </div>
    )
}
