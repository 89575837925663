import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import QueryString from 'qs';
import axiosApi from '../functions/axiosApi';
import { banner, basicProps, currencyType, newsLetterType, orderType } from '../utils/interfaces';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { MetaTags } from 'react-meta-tags';
import { binacePriceApiPath, isPC, publicPath } from '../functions/basicFunctions';
import TelegramIcon from '@mui/icons-material/Telegram';
import '../css/pages/home/home.css';
import PreviewNews from '../components/PreviewNews';
import axios from 'axios';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Carousel from "nuka-carousel"
import LatestOrderAlert from './orders/LatestOrderAlert';

interface myCurrencyType extends currencyType {
    price: number
}

const bgGradient = 'linear-gradient(#277ED5, #A5CFEF)';
const bgStartGif = Math.floor(Math.random() * 2) === 1 ? "url('" + publicPath + "images/background-khaoticfuture.gif')" : "url('" + publicPath + "images/2091769.gif')";

export default function Home(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [lastestNews, setLastestNews] = useState<newsLetterType[]>([])
    const [trendingCurrencies, setTrendingCurrencies] = useState<myCurrencyType[]>([])
    const [mainPhrase, setMainPhrase] = useState<React.ReactNode>();
    const [mobile_top_carousel_banners, set_mobile_top_carousel_banners] = useState<banner[]>([])
    const [pc_top_bg, set_pc_top_bg] = useState<banner>()
    const [latestOrder, setLatestOrder] = useState<orderType>()

    const getPriceOnBinance = () => {
        
        trendingCurrencies.forEach((currency, i) => {

            axios.get(binacePriceApiPath + currency.symbol + 'USDT')
            .then(binanceResponse => {

                const data = binanceResponse.data;
                const coinList = [...trendingCurrencies];
                //coinList[i].price = parseFloat(parseFloat(data.price).toFixed(3));
                coinList[i].price = parseFloat(data.price);
                setTrendingCurrencies(coinList)

            })
        })
    }

    useEffect(() => {
        
        const randomPhrases = [
            "Explorons ensemble la Cryptosphère",
            "C'est un plaisir de te revoir 😊",
            "Un nouveau jour, un petit pas vers de grands objectifs",
            "Une petite action aujourd'hui peut tout changer pour demain",
            "N'attends pas. Le moment ne sera jamais parfait",
            "Le succès n'attend pas, agis maintenant.",
            "Chaque petit progrès compte, commences dès maintenant.",
            "Ne laisses pas les doutes t'arrêter, commences aujourd'hui",
            "L'action crée l'opportunité, commences maintenant.",
        ]

        const randomID = Math.floor(Math.random() * randomPhrases.length);

        if(basicDatas.isLoggedIn) {

            const date = new Date();

            setMainPhrase (
                <>
                    {(date.getHours() < 13) ? 'Bonjour,' : 'Bonsoir,'} { (authUser.firstName && authUser.firstName !== '') ? authUser.firstName : authUser.lastName } <br />
                    <Typography variant={isPC ? 'h4' : 'h6'} sx={{mt: 3}}>
                        { randomPhrases[randomID] }
                    </Typography> 
                </>
            )
        
        }

        else setMainPhrase(
            <>
                Achetez et vendez plus de 30 cryptomonnaies sur CryptoFiat de manière simple et sécurisée.
            </>
        );

    }, [basicDatas.isLoggedIn])

    useEffect(() => {
        
        axiosApi.post('home-content/trending-currencies.php', QueryString.stringify({limit: isPC ? 5 : 2}))
        .then(response => {

            if(response.data.status === 'success') {
                setTrendingCurrencies(response.data.trendingCurrencies);
            }
        })

    }, [])

    useEffect(() => {
        
        axiosApi.post('home-content/home-banners.php')
        .then(response => {

            if(response.data.status === 'success') {
                set_mobile_top_carousel_banners(response.data.banners.mobile_top_carousel)
                set_pc_top_bg(response.data.banners.pc_top_bg);
            }
        })

        axiosApi.post('home-content/latest-order.php')
        .then(response => {

            if(response.data.status === 'success') {
                setLatestOrder(response.data.latestOrder)
            }
        })

    }, [])

    useEffect(() => {

        let getPriceInterval;
        
        if(trendingCurrencies.length !== 0) {
            getPriceInterval = setInterval(() => {
                getPriceOnBinance();
            }, 5000)
        }

        return () => {
            clearInterval(getPriceInterval)
        }
    }, [trendingCurrencies])

    useEffect(() => {
        getNews();
    }, [])
    
    
    

    useEffect(() => {

        const appDiv = document.getElementById('App');
        const AppInterBlur = document.getElementById('AppInterBlur')

        if(appDiv && AppInterBlur && isPC){
            //appDiv.style.background = "linear-gradient(-20deg, transparent 25%, var(--site-color))";
            appDiv.style.backgroundImage = "url('" + pc_top_bg?.img + "')";
            appDiv.style.backgroundPosition = 'center';
            appDiv.style.backgroundSize = 'cover';
            appDiv.style.minHeight = "100vh"
            appDiv.style.position = "relative"

            AppInterBlur.style.display = 'block';
            AppInterBlur.style.background = "linear-gradient(-35deg, transparent 5%, var(--site-color))";
            AppInterBlur.style.position = 'absolute';
            AppInterBlur.style.opacity = '1';
            AppInterBlur.style.top = '0%';
            AppInterBlur.style.left = '0%';
            AppInterBlur.style.right = '0%';
            AppInterBlur.style.bottom = '0%';
            AppInterBlur.style.height = '100vh';
        }

        return () => {
            if(appDiv && AppInterBlur) {
                AppInterBlur.style.display = 'none';
                appDiv.style.background = "inherit";
            } 
        }

    }, [pc_top_bg])
    

    const getNews = () => {

        axiosApi.post("ctr/communication/news-letter/web-news/get-news.php", QueryString.stringify({ offset: 0, limit: 1 }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDataArray = lastestNews.concat(response.data.news);
                setLastestNews(newDataArray);
            }
        })
    }

    return (
        <div style={{zIndex: 500, position: 'relative'}}>

            <MetaTags id="order-infos">
                <title>{basicDatas.appName}</title>
                <meta name="description" content={basicDatas.appName + " - Votre interface entre la crypto et la monnaie locale en Afrique"} />
                <meta property="og:title" content={basicDatas.appName + " - Votre interface entre la crypto et la monnaie locale en Afrique"} />
            </MetaTags>

            {
                !isPC && latestOrder &&

                <>
                    <LatestOrderAlert latestOrder={latestOrder} />
                </>
            }

            <Box 
                sx={{ 
                    display: isPC ? 'flex' : 'block',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    textAlign: isPC ? 'start' : 'center',
                    pt: isPC ? 8 : 2,
                    color: isPC ? 'white' : 'inherit'
                }}
            >

                <div style={{
                        paddingRight: isPC ? '50px' : '0px',
                        maxWidth: isPC ? '70%' : '100%'
                    }}
                >

                    <div style={{marginBottom: isPC ? '20px' : '15px'}}>

                        <Carousel
                            autoplay
                            autoplayInterval={5000}
                            enableKeyboardControls
                            wrapAround
                            speed={1000}
                            withoutControls={true}
                            adaptiveHeight={true}
                            style={{
                                marginBottom: 30,
                                display: isPC ? 'none' : 'block'
                            }}
                        >

                            {
                                mobile_top_carousel_banners.length === 0

                                ?

                                <Skeleton
                                    animation='wave'
                                    variant='rectangular'
                                    sx={{
                                        height: 150,
                                        borderRadius: '5px',
                                    }}
                                />

                                :
                                
                                mobile_top_carousel_banners.map((itemBanner, i) => {

                                    return (
                                        <Box
                                            key={i}
                                            sx={{
                                                height: 150,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                borderRadius: '5px',
                                                backgroundImage: "url('" + itemBanner.img +  "')" 
                                            }}
                                        >

                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    height: '100%'
                                                }}
                                            >

                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        textAlign: 'right',
                                                        borderBottomLeftRadius: '5px',
                                                        borderBottomRightRadius: '5px'
                                                    }}
                                                >

                                                    {
                                                        itemBanner.buttonTitle?.length !== 0 &&

                                                        <>

                                                            {
                                                                itemBanner.openNewTab == 1 

                                                                ?

                                                                <a href={ itemBanner.redirectUrl } target='_blank' className='link'>
                                                                    <Button
                                                                        variant='contained'
                                                                        sx={{
                                                                            textTransform: 'none',
                                                                            color: 'white',
                                                                            fontSize: 'small',
                                                                            mr: 0.5,
                                                                            mb: 0.5,
                                                                            p: 1.5,
                                                                            pt: 0,
                                                                            pb: 0,
                                                                            backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'
                                                                        }}
                                                                    >
                                                                        { itemBanner.buttonTitle }
                                                                    </Button>
                                                                </a>

                                                                :

                                                                <Link to={ itemBanner.redirectUrl + '' } className='link'>
                                                                    <Button
                                                                        variant='contained'
                                                                        sx={{
                                                                            textTransform: 'none',
                                                                            color: 'white',
                                                                            fontSize: 'small',
                                                                            mr: 0.5,
                                                                            mb: 0.5,
                                                                            p: 1.5,
                                                                            pt: 0,
                                                                            pb: 0,
                                                                            backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'
                                                                        }}
                                                                    >
                                                                        { itemBanner.buttonTitle }
                                                                    </Button>
                                                                </Link>
                                                            }

                                                        </>
                                                    }

                                                </Box> 

                                            </Box>
                                        </Box>
                                    )
                                })
                            }

                        </Carousel>


                        <Typography 
                            className='Montserrat'
                            sx={{
                                opacity: 0.8,
                                fontWeight: 'bold',
                                fontSize: basicDatas.isLoggedIn ? isPC ?  '60px' : '20px' : isPC ?  '45px' : '20px',
                                marginBottom: isPC ? '50px' : '0px'
                            }}
                        >

                            { mainPhrase }
                            
                        </Typography>
                    
                    </div>

                    <Box>

                    {
                        basicDatas.isLoggedIn 
                        ?   <Typography style={{fontSize:'20px', color: 'white'}} className='pc-app-el'>
                                Accéder à un service 
                            </Typography>
                        :
                            <Typography style={{fontSize:'20px'}} className='pc-app-el'>
                                N'attendez plus, lancez-vous dès maintenant 
                            </Typography>
                    }

                    <br /> 

                    <Box 
                        sx={{
                        mt: 2,
                        mb: isPC ? 5 : 2,
                        display: 'grid',
                        gridTemplateColumns: isPC ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
                        gap: 2,
                        maxWidth: 400
                        }}
                    >

                        <Link to="/trade/buy/" className='link'>
                            <Button variant='contained' fullWidth sx={{p: 1.2}}>
                                Acheter crypto
                            </Button>
                        </Link>

                        <Link to="/trade/sell/" className='link'>
                            <Button variant='contained' 
                                    color='error'
                                    fullWidth
                                    sx={{p: 1.2}} >
                                Vendre crypto
                            </Button>
                        </Link>

                        <Link to="/swap/" className='link'>
                            <Button  
                                variant='contained'
                                color='inherit' 
                                fullWidth 
                                sx={{p: 1.2, textAlign: 'left'}}
                                startIcon={<SwapHorizIcon />}
                                >
                                Échanger crypto
                            </Button>
                        </Link>

                        <Link to="/orders/" className='link'>
                            <Button 
                                variant='contained'
                                color='inherit' 
                                fullWidth
                                sx={{p: 1.2}} 
                            >
                                Mes ordres
                            </Button>
                        </Link>

                        <Link to="/wallet/" className='link'>
                            <Button  
                                variant='contained'
                                color='inherit' 
                                fullWidth
                                startIcon={ <AccountBalanceWalletIcon />}
                                sx={{p: 1.2}} 
                            >
                                Portefeuille
                            </Button>
                        </Link>

                        <Link to="/cft-token/sellers/" className='link'>
                            <Button  
                                variant='contained'
                                color='inherit'
                                fullWidth
                                startIcon={ <AddIcon/>}
                                sx={{p: 1.2}} 
                            >
                                Recharger CFT
                            </Button>
                        </Link>

                    </Box>

                    <div style={{marginTop: isPC ? '25px' : '20px'}}>
                        <Link to="/about/" className='link text-white'>
                            À props de nous
                            <KeyboardDoubleArrowRightIcon fontSize='small' />
                        </Link>
                    </div>

                    </Box>

                </div>

                <div className='pc-app-el' style={{textAlign: 'right'}}>

                    <Box sx={{height: 480}}></Box>

                    <Box 
                        sx={{
                            mt: 3,
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            color: 'white',
                            p: 2,
                            borderRadius: 1
                        }}
                    >

                    </Box>

                </div>

            </Box>




            <Box
                sx={{
                    mt: isPC ? 20 : 3,
                    mb: isPC ? 15 : 7,
                    textAlign: 'center'
                }}
            >

                <Typography 
                    variant={isPC ? 'h3' : 'h6'} 
                    className='Montserrat'
                    fontWeight={'bold'} sx={{mb: 3}}
                >
                    Gagnez <span style={{color: 'orange'}}>500 XAF</span> pour chaque ami.e invité.e.
                </Typography>

                <Typography>
                    <Link to="/sponsorships/" className='link'>
                        <Button 
                            variant='contained'
                            sx={{
                                p: isPC ? 1.5 : 1.3,
                                pl: 4,
                                pr: 4,
                                fontSize: isPC ? 20 : 13,
                                fontWeight: 'bold',
                                borderRadius: 20,
                                backgroundImage: 'linear-gradient(orange, orange, white)'
                            }}
                            endIcon={<KeyboardDoubleArrowRightIcon fontSize='small' />}
                        >
                            Commencer à gagner
                        </Button>
                    </Link>
                </Typography>

            </Box>



            <Box
                sx={{
                    mb: 10,
                    display: isPC ? 'block' : 'none'
                }}
            >

                <Carousel
                    autoplay
                    autoplayInterval={5000}
                    enableKeyboardControls
                    wrapAround
                    speed={1000}
                    withoutControls={true}
                    adaptiveHeight={true}
                    cellSpacing={25}
                    dragThreshold={0.3}
                    slidesToShow={3}
                    slidesToScroll={1}
                >

                    {
                        mobile_top_carousel_banners.length === 0

                        ?

                        [1,2,3].map(() => {
                            return (
                                <Skeleton
                                    animation='wave'
                                    variant='rectangular'
                                    sx={{
                                        height: 175,
                                        borderRadius: '5px',
                                    }}
                                />
                            )
                        })

                        :
                        
                        mobile_top_carousel_banners.map((itemBanner, i) => {

                            return (
                                <Box
                                    key={i}
                                    sx={{
                                        height: 175,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        borderRadius: '5px',
                                        backgroundImage: "url('" + itemBanner.img +  "')" 
                                    }}
                                >

                                    <Box
                                        sx={{
                                            position: 'relative',
                                            height: '100%'
                                        }}
                                    >

                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                textAlign: 'right',
                                                borderBottomLeftRadius: '5px',
                                                borderBottomRightRadius: '5px'
                                            }}
                                        >

                                            {
                                                itemBanner.buttonTitle?.length !== 0 &&

                                                <>

                                                    {
                                                        itemBanner.openNewTab == 1 

                                                        ?

                                                        <a href={ itemBanner.redirectUrl } target='_blank' className='link'>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    color: 'white',
                                                                    fontSize: 'small',
                                                                    mr: 0.5,
                                                                    mb: 0.5,
                                                                    p: 1.5,
                                                                    pt: 0,
                                                                    pb: 0,
                                                                    backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'
                                                                }}
                                                            >
                                                                { itemBanner.buttonTitle }
                                                            </Button>
                                                        </a>

                                                        :

                                                        <Link to={ itemBanner.redirectUrl + '' } className='link'>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    color: 'white',
                                                                    fontSize: 'small',
                                                                    mr: 0.5,
                                                                    mb: 0.5,
                                                                    p: 1.5,
                                                                    pt: 0,
                                                                    pb: 0,
                                                                    backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'
                                                                }}
                                                            >
                                                                { itemBanner.buttonTitle }
                                                            </Button>
                                                        </Link>
                                                    }

                                                </>
                                            }

                                        </Box> 

                                    </Box>
                                </Box>
                            )
                        })
                    }

                </Carousel>

            </Box>


            <Box 
                sx={{
                    mb: isPC ? 15 : 5,
                    display: 'grid',
                    gap: isPC ? 3 : 1.5,
                    gridTemplateColumns: isPC ? 'repeat(5, 1fr)' : 'repeat(2, 1fr)'
                }}
            >

                {
                    (!trendingCurrencies || trendingCurrencies.length === 0) &&

                    (isPC ? [1,2,3,4,5] : [1,2]).map(i => {
                        return (
                            <Typography
                                sx={{
                                    p: 2,
                                    borderRadius: 1,
                                    color: "GrayText",
                                    background: 'linear-gradient(-10deg, transparent 1%, #eee)'
                                }}
                            >

                                <Skeleton
                                    variant='circular'
                                    animation='wave'
                                    sx={{
                                        float: 'right',
                                        width: 25,
                                        height: 25,
                                    }}
                                />

                                <Skeleton
                                    variant='rectangular'
                                    animation='wave'
                                    sx={{
                                        width:'35%',
                                        verticalAlign: 'middle',
                                        borderRadius: 0.5
                                    }}
                                />

                                <Skeleton
                                    variant='rectangular'
                                    animation='wave'
                                    sx={{
                                        mt: 2,
                                        verticalAlign: 'middle',
                                        borderRadius: 0.5
                                    }}
                                />
                                
                            </Typography>
                        )
                    })
                }

                {
                    trendingCurrencies?.map(itemTrandingCurrency => {
                        return (
                            <Typography
                                sx={{
                                    p: 2,
                                    borderRadius: 1,
                                    color: "GrayText",
                                    background: 'linear-gradient(-10deg, transparent 1%, #eee)'
                                }}
                            >

                                <Typography
                                    sx={{color: '#222'}}
                                >

                                    <Avatar 
                                        src={itemTrandingCurrency.logo}
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            float: 'right'
                                        }}
                                    />
                                    {itemTrandingCurrency.symbol}
                                </Typography>

                                {
                                    (!parseFloat(itemTrandingCurrency.price + ''))

                                    ?

                                    <Skeleton
                                        variant='rectangular'
                                        animation='wave'
                                        sx={{
                                            mt: 1.5,
                                            verticalAlign: 'middle',
                                            borderRadius: 0.5
                                        }}
                                    />

                                    :
                               
                                    <Typography
                                        variant={isPC ? 'h5' : 'h6'}
                                    >
                                        { itemTrandingCurrency.price }<span style={{fontSize: 'small'}}>$</span>
                                    </Typography>

                                    }
                                
                            </Typography>
                        )
                    })
                } 
            </Box>





            <Box
                sx={{
                    mb: isPC ? 15 : 7,
                    display: isPC ? 'flex' : 'block',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                }}
            >


                {
                    isPC 
                    
                    ?

                        <>

                            <Box
                                sx={{
                                    width: isPC ? '40%' : '100%'
                                }}
                            >
                                <Typography 
                                    variant={isPC ? 'h4' : 'h5'}
                                    fontWeight={800}
                                    className='Montserrat'
                                >
                                    Dernières nouvelles
                                </Typography>

                                <Typography
                                    variant={isPC ? 'h6' : 'inherit'}
                                    sx={{
                                        mt: 4,
                                        mb: 4,
                                        color: 'GrayText'
                                    }}
                                >
                                    
                                    Pour tirer le meilleur parti de votre expérience et bénéficier de toutes nos fonctionnalités, nous vous invitons à consulter régulièrement nos annonces pour rester informé(e) des dernières mises à jour.
                                </Typography>

                                <div>
                                    <Link to="/news/" className='link text-primary'>
                                        Accéder aux annonces
                                        <KeyboardDoubleArrowRightIcon fontSize='small' />
                                    </Link>
                                </div>

                                <Box sx={{
                                        bgcolor: 'rgba(245, 245, 245, 0.8)',
                                        margin: 'auto',
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 2,
                                        mt: 4
                                    }}
                                >

                                    <div style={{width: '65%', fontSize: 'small', paddingRight: '5px'}}>
                                        Abonnez-vous au canal Telegram pour ne manquer aucune nouvelle.
                                    </div> 

                                    <div style={{width: '30%'}}>
                                        <a href='https://t.me/cryptofiatex' target='_blank' className='link'>
                                        <Button 
                                            variant='contained' 
                                            startIcon={<TelegramIcon />} 
                                            fullWidth
                                            sx={{backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'}}
                                        >
                                            S'abonner
                                        </Button>
                                        </a>
                                    </div>

                                </Box>

                            </Box>

                            <Box
                                sx={{
                                    width: isPC ? '35%' : '100%',
                                    boxShadow: 2,
                                    borderRadius: 2,
                                    marginRight: '7.5%',
                                    p: 1
                                }}
                            >

                                {
                                    (lastestNews.length === 0) &&

                                    <ListItem 
                                        alignItems="flex-start"
                                    >

                                        <ListItemAvatar>
                                            <Skeleton 
                                                variant='circular'
                                                animation='wave'
                                                width={40}
                                                height={40}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary={ 
                                            <Skeleton variant='rectangular' animation='wave' height={12} width={'75%'} sx={{borderRadius: 0.3}} /> 
                                        }
                                        secondary={
                                            <>

                                                <Skeleton
                                                    variant='rectangular'
                                                    animation='wave'
                                                    height={10}
                                                    sx={{
                                                        mt: 2,
                                                        borderRadius: 1
                                                    }}
                                                />

                                                <Skeleton
                                                    variant='rectangular'
                                                    animation='wave'
                                                    height={200}
                                                    sx={{
                                                        mt: 3,
                                                        mb: 4,
                                                        boxShadow: 5,
                                                        borderRadius: 1
                                                    }}
                                                />

                                                {
                                                    [1,2,3].map(i => {
                                                        return (
                                                            <Skeleton
                                                                variant='rectangular'
                                                                animation='wave'
                                                                height={7}
                                                                sx={{
                                                                    mt: 2,
                                                                    borderRadius: 1
                                                                }}
                                                            />
                                                        )
                                                    })
                                                }

                                                <Skeleton
                                                    variant='rectangular'
                                                    animation='wave'
                                                    height={7}
                                                    width={'30%'}
                                                    sx={{
                                                        mt: 2,
                                                        borderRadius: 1
                                                    }}
                                                />

                                                
                                            </>
                                        }
                                        />
                                    </ListItem>
                                }

                                {
                                    lastestNews.map((post, index) => {
                                        return (
                                        <Box sx={{
                                                bgcolor: isPC ? 'white' : 'rgba(245, 245, 245, 0.8)',
                                                borderRadius: 3
                                            }}
                                            key={index}>
                                            <PreviewNews maxLength={isPC ? 150 : 100} basicDatas={basicDatas} post={post} />
                                        </Box>
                                        )
                                    })
                                } 

                            </Box>
                        </>

                    :
                        <>

                            <Box>

                                <Typography 
                                    variant={isPC ? 'h4' : 'h5'}
                                    fontWeight={800}
                                    className='Montserrat'
                                >
                                    Dernières nouvelles
                                </Typography>

                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        mt: 2.5
                                    }}
                                >

                                    {
                                        (lastestNews.length === 0) &&

                                        <ListItem 
                                            alignItems="flex-start"
                                        >

                                            <ListItemAvatar>
                                                <Skeleton 
                                                    variant='circular'
                                                    animation='wave'
                                                    width={40}
                                                    height={40}
                                                />
                                            </ListItemAvatar>

                                            <ListItemText
                                            primary={ 
                                                <Skeleton variant='rectangular' animation='wave' height={12} width={'75%'} sx={{borderRadius: 0.3}} /> 
                                            }
                                            secondary={
                                                <>

                                                    <Skeleton
                                                        variant='rectangular'
                                                        animation='wave'
                                                        height={10}
                                                        sx={{
                                                            mt: 2,
                                                            borderRadius: 1
                                                        }}
                                                    />

                                                    <Skeleton
                                                        variant='rectangular'
                                                        animation='wave'
                                                        height={200}
                                                        sx={{
                                                            mt: 3,
                                                            mb: 4,
                                                            boxShadow: 5,
                                                            borderRadius: 1
                                                        }}
                                                    />

                                                    {
                                                        [1,2,3].map(i => {
                                                            return (
                                                                <Skeleton
                                                                    variant='rectangular'
                                                                    animation='wave'
                                                                    height={7}
                                                                    sx={{
                                                                        mt: 2,
                                                                        borderRadius: 1
                                                                    }}
                                                                />
                                                            )
                                                        })
                                                    }

                                                    <Skeleton
                                                        variant='rectangular'
                                                        animation='wave'
                                                        height={7}
                                                        width={'30%'}
                                                        sx={{
                                                            mt: 2,
                                                            borderRadius: 1
                                                        }}
                                                    />

                                                    
                                                </>
                                            }
                                            />
                                        </ListItem>
                                    }

                                    {
                                        lastestNews.map((post, index) => {
                                            return (
                                            <Box sx={{
                                                    bgcolor: isPC ? 'white' : 'rgba(245, 245, 245, 0.8)',
                                                    borderRadius: 3
                                                }}
                                                key={index}>
                                                <PreviewNews maxLength={isPC ? 150 : 100} basicDatas={basicDatas} post={post} />
                                            </Box>
                                            )
                                        })
                                    }

                                </Box>

                                <Typography
                                    variant={isPC ? 'h6' : 'inherit'}
                                    sx={{
                                        mt: 4,
                                        mb: 4,
                                        color: 'GrayText'
                                    }}
                                >
                                    Pour tirer le meilleur parti de votre expérience et bénéficier de toutes nos fonctionnalités, nous vous invitons à consulter régulièrement nos annonces pour rester informé(e) des dernières mises à jour.
                                </Typography>

                                <div>
                                    <Link to="/news/" className='link text-primary'>
                                        Accéder aux annonces
                                        <KeyboardDoubleArrowRightIcon fontSize='small' />
                                    </Link>
                                </div>

                                <Box sx={{
                                        bgcolor: 'rgba(245, 245, 245, 0.8)',
                                        margin: 'auto',
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 2,
                                        mt: 4
                                    }}
                                >

                                    <div style={{width: '55%', fontSize: 'small', paddingRight: '5px'}}>
                                        Abonnez-vous au canal Telegram pour ne manquer aucune nouvelle.
                                    </div> 

                                    <div style={{width: '40%'}}>
                                        <a href='https://t.me/cryptofiatex' target='_blank' className='link'>
                                        <Button 
                                            variant='contained' 
                                            startIcon={<TelegramIcon />} 
                                            fullWidth
                                            sx={{backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'}}
                                        >
                                            S'abonner
                                        </Button>
                                        </a>
                                    </div>

                                </Box>

                            </Box>

                        </>
                }

            </Box>

            

            <Box
                sx={{
                    mb: isPC ? 20 : 7,
                    display: isPC ? 'flex' : 'block',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                }}
            >

                <Box
                    sx={{
                        width: isPC ? '52%' : '100%'
                    }}
                >
                    <img 
                        src={publicPath + "images/kyc_banner.png?6210"} 
                        alt="CRYPTO-FIAT"
                        style={{
                            width: '100%',
                            borderRadius: '30px'
                        }} 
                    />
                </Box>

                <Box
                    sx={{
                        width: isPC ? '40%' : '100%'
                    }}
                >
                    <Typography 
                        variant={isPC ? 'h4' : 'h5'}
                        className='Montserrat'
                        fontWeight={800}
                        sx={{
                            mt: isPC ? 0 : 2.5
                        }}
                    >
                        Vérifier mon compte
                    </Typography>

                    <Typography
                        variant={isPC ? 'h6' : 'inherit'}
                        sx={{
                            mt: isPC ? 4 : 3,
                            mb: 4,
                            color: 'GrayText'
                        }}
                    >
                        Faites vérifier votre compte { basicDatas.appName } pour accéder à nos services. Vos informations privées sont protégées.
                    </Typography>

                    <Typography>
                        <Link to="/kyc/" className='link'>
                            <Button 
                                variant='contained'
                                sx={{
                                    p: isPC ? 2 : 1.3,
                                    pl: 4,
                                    pr: 4,
                                    backgroundImage: bgGradient
                                }}
                            >
                                Vérifier maintenant
                            </Button>
                        </Link>
                    </Typography>

                </Box>

            </Box>



            <Box
                sx={{
                    mb: isPC ? 15 : 7,
                    display: isPC ? 'flex' : 'block',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                }}
            >

                <Box
                    sx={{
                        width: isPC ? '40%' : '100%'
                    }}
                >
                    <Typography 
                        variant={isPC ? 'h4' : 'h5'}
                        className='Montserrat'fontWeight={800}
                        sx={{
                            mt: isPC ? 0 : 2.5
                        }}
                    >
                        Fournir de la liquidité
                    </Typography>

                    <Typography
                        variant={isPC ? 'h6' : 'inherit'}
                        sx={{
                            mt: isPC ? 4 : 3,
                            mb: 4,
                            color: 'GrayText'
                        }}
                    >
                        En fournissant de la liquidité, vous nous aidez à accélérer les transactions et à améliorer l'expérience utilisateur. En retour, vous bénéficiez d'un rendement de 10% sur 10 mois.
                    </Typography>

                    <Typography>
                        <Link to="/liquidity-provider/" className='link'>
                            <Button 
                                variant='contained'
                                sx={{
                                    p: isPC ? 2 : 1.3,
                                    pl: 4,
                                    pr: 4,
                                    mb: isPC ? 0 : 3,
                                    backgroundImage: bgGradient
                                }}
                            >
                                En savoir plus
                            </Button>
                        </Link>
                    </Typography>

                </Box>

                <Box
                    sx={{
                        width: isPC ? '52%' : '100%'
                    }}
                >
                    <img 
                        src={publicPath + "images/liquidity_banner_home.png?6210"} 
                        alt="CRYPTO-FIAT"
                        style={{
                            width: '100%',
                            borderRadius: '30px'
                        }} 
                    />
                </Box>

            </Box>


            <Box
                sx={{
                    mb: isPC ? 15 : 7,
                    textAlign: 'center',
                    bgcolor: '#F7F7F7',
                    borderRadius: isPC ? 10 : 1,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    color: 'White',
                    overflow: 'auto',
                    backgroundImage: bgStartGif
                }}
            >

                <Box
                    sx={{
                        p: 5,
                        borderRadius: isPC ? 10 : 1,
                        bgcolor: 'rgba(46, 138, 236, 0.85)'
                    }}
                >

                    <Typography
                        variant={isPC ? 'h4' : 'h5'}
                        className='Montserrat'
                        sx={{fontWeight: 800}}
                    >
                        Lancez-vous dès maintenant
                    </Typography>

                    <Typography
                        variant={isPC ? 'h6' : 'inherit'}
                        sx={{
                            mt: 2,
                            mb: 4
                        }}
                    >
                        Commencez à acheter et vendre vos cryptomonnaies en toute simplicité sur CryptoFiat !
                    </Typography>

                    <Typography>

                        <Link to="/trade/buy/" className='link'>
                            <Button 
                                variant='contained' 
                                sx={{
                                    p: 2,
                                    pl: 4,
                                    pr: 4,
                                    borderRadius: 20,
                                    background: bgGradient
                                }}
                            >
                                Acheter crypto
                            </Button>
                        </Link>

                        {!isPC && <Typography sx={{mb:2}}></Typography>}

                        <Link to="/trade/sell/" className='link'>
                            <Button 
                                variant='contained' 
                                color='inherit'
                                sx={{
                                    p: 2, 
                                    ml: isPC ? 2 : 0,
                                    pl: 4,
                                    pr: 4,
                                    borderRadius: 20
                                }} 
                            >
                                Vendre crypto
                            </Button>
                        </Link>

                        {!isPC && <Typography sx={{mb:2}}></Typography>}

                        <Link to="/swap/" className='link'>
                            <Button 
                                variant='contained' 
                                color='inherit'
                                sx={{
                                    p: 2, 
                                    ml: isPC ? 2 : 0,
                                    pl: 4,
                                    pr: 4,
                                    borderRadius: 20
                                }} 
                            >
                                Swap
                            </Button>
                        </Link>

                    </Typography>

                </Box>

            </Box>






            <Box
                sx={{
                    mb: isPC ? 15 : 7,
                }}
            >

                <Typography
                    variant={isPC ? 'h4' : 'h5'}
                    fontWeight={800}
                    textAlign='center'
                    className='Montserrat'
                    sx={{
                        mb: isPC ? 4 : 3
                    }}
                >
                    {isPC ? 'Quelques questions fréquentes (FAQ)' : 'Questions fréquentes'}
                </Typography>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Qu'est-ce que CryptoFiat ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            CryptoFiat est une plateforme d'échange de cryptomonnaies qui facilite l'achat et la vente de plus de 30 cryptomonnaies avec des monnaies locales en Afrique, en mettant particulièrement l'accent sur la région de l'Afrique Centrale.
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Comment puis-je acheter des cryptomonnaies sur CryptoFiat ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            Pour acheter des cryptomonnaies sur CryptoFiat, vous devez créer un compte, effectuer une vérification d'identité (KYC), et passer une commande d'achat ou de vente.
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Est-il possible d'effectuer des transactions hors plateforme ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            <b className='text-danger'>NON</b> <br />
                            Les transactions en dehors de la plateforme ne sont pas autorisées, et nous déclinons toute responsabilité quant aux pertes encourues en dehors de Cryptofiat-ex.com.
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Combien de temps prend la vérification d'identité (KYC) ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                        La vérification d'identité peut prendre jusqu'à 24 heures à compter du moment où vous avez soumis votre demande. <br />
                        Si le statut reste en attente pendant plus de 24 heures, veuillez contacter le support.
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Quel est le prix de la crypto [...] actuellement ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            Les cryptomonnaies sont des actifs volatils, ce qui signifie que leurs prix ne sont jamais fixes.
                            <br /> <br />
                            Pour connaître le prix d'une crypto à un instant donné, suivez ces étapes :

                            <ol>
                                <li>
                                    Dirigez-vous vers la page <Link to={'/trade/'} className='link text-primary'>ACHAT/VENTE CRYPTO</Link>;
                                </li>
                                <li>
                                    Sélectionnez la crypto concernée (choisissez également le sens ACHETER ou VENDRE).
                                </li>
                                <li>
                                    Suivez la procédure jusqu'à l'étape de quantités;
                                </li>
                                <li>
                                    Mettez 1 (un) dans le champ <b>Quantité crypto</b>.
                                </li>
                            </ol>

                            L'équivalent en FIAT sera directement affiché dans le champ <b>Quantité FIAT</b>
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Quelles sont les mesures de sécurité mises en place par CryptoFiat ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            CryptoFiat met en place des mesures de sécurité avancées, telles que la vérification d'identité (KYC), la protection des données personnelles, et la sécurisation des transactions par des systèmes de détection de fraudes. <br />
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight='bold'>
                            Quels sont les pays couverts par CryptoFiat ?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            CryptoFiat opère principalement en Afrique, avec un focus particulier sur l'Afrique Centrale. Cependant, la plateforme vise à étendre ses services à d'autres régions à l'avenir.
                        </Typography>
                    </AccordionDetails>

                </Accordion>

                <Box sx={{textAlign:'center', mt: 3}}>
                    <Link to="/help/" className='link'>                    
                        <Button
                            variant='contained'
                            endIcon={<KeyboardDoubleArrowRightIcon fontSize='small' />}
                            sx={{
                                backgroundImage: bgGradient,
                                borderRadius: 50
                            }}
                        >
                            Page d'aide
                        </Button>
                    </Link>
                </Box>
                

            </Box>





            <Box
                sx={{
                position: 'relative',
                height: isPC ? 450 : 130,
                width: '100%',
                clear: 'right',
                borderRadius: isPC ? 10 : 1,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundImage: isPC ? "url('" + publicPath + "images/cf_pay_homme_banner.png')" : "url('" + publicPath + "images/cf_pay_homme_banner_phone.png')"
                }}
            >

                <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0
                }}
                >

                {
                    isPC 

                    ?

                    <a
                        href={'https://medium.com/@cryptofiat1022/cest-quoi-crypto-fiat-pay-et-comment-l-utiliser-814e2ca1568a'}
                        className='link'
                        target='_blank'
                        style={{
                            color: 'white'
                        }}
                    >
                        <Button
                            variant='contained' 
                            sx={{
                            mr: isPC ? 5 : 1,
                            mb: isPC ? 5 : 1,
                            p: isPC ? 2 : '',
                            pl: isPC ? 4 : '', 
                            pr: isPC ? 4 : '',
                            fontSize: 'small',
                            backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'
                            }}
                        >
                            
                            En savoir plus
                        </Button>

                    </a>

                    :

                    <Typography
                    sx={{
                        mr: 2,
                        mb: 1,
                    }}
                    >

                        <a
                        href='https://medium.com/@cryptofiat1022/cest-quoi-crypto-fiat-pay-et-comment-l-utiliser-814e2ca1568a'
                        target='_blank'
                        style={{
                            color: 'white',
                            fontSize: 14
                        }}
                        >
                        En savoir plus
                        </a>
                    </Typography>
                }

                </Box>

            </Box>

        </div>
    )
}
