import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { cftTokenInfo } from '../functions/basicFunctions';
import { Badge } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { User } from '../utils/interfaces';

interface headerProps {
    basicDatas: any
}

const Header = (props: headerProps) => {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser as User;

    const navigate = useNavigate()
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElTrade, setAnchorElTrade] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenTradeMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElTrade(event.currentTarget);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseTradeMenu = () => {
        setAnchorElTrade(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar style={{
            position: 'sticky',
            top: '0%'
        }}>

            <Container style={{maxWidth:'1200px', padding:'0px'}}>

                <Toolbar 
                    disableGutters 
                    style={{
                        maxWidth: window.screen.width > 700 ? '1200px' : '97%',
                    }}
                >

                    <Typography
                        variant="h6"
                        noWrap
                        className='goldman'
                        onClick={() => navigate('/')}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        {basicDatas.appName}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >

                        <MenuIcon /> 

                        </IconButton>

                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                            <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate("/trade/buy/")
                                }}>
                                <Typography textAlign="center">Acheter Crypto</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate("/trade/sell/")
                                }}>
                                <Typography textAlign="center">Vendre Crypto</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate("/swap/")
                                }}>
                                <Typography textAlign="center">Swap</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate("/wallet/")
                                }}>
                                <Typography textAlign="center">Portefeuille</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate("/cft-token/")
                                }}>
                                
                                <Badge badgeContent='Nouveau' color='error'>
                                    <Typography textAlign="center" style={{marginTop:'7px'}}>Token { cftTokenInfo.symbol }</Typography>
                                </Badge>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate("/news/")
                                }}>
                                <Typography textAlign="center">News</Typography>
                            </MenuItem>
                        
                        </Menu>
                    </Box>
                    
                    <Typography
                        variant="h6"
                        noWrap
                        onClick={() => navigate('/')}
                        sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'goldman',
                        fontWeight: 500,
                        letterSpacing: '.1rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        cursor: 'pointer'
                        }}
                    >
                        {basicDatas.appName}
                    </Typography>

                    <Box sx={{ flexGrow: 1, alignItems:'center', display: { xs: 'none', md: 'flex' } }}>
                        
                        <Tooltip title="Afficher les options">
                            <IconButton onClick={handleOpenTradeMenu} sx={{ p: 0 }}>
                                <Button
                                    sx={{ my: 2, color: 'white', marginLeft:'10px' }}
                                    color="primary"
                                >
                                    Acheter / Vendre / Swap
                                </Button>
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElTrade}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElTrade)}
                            onClose={handleCloseTradeMenu}
                            >

                            <MenuItem onClick={() => {handleCloseTradeMenu(); navigate('/trade/buy/')}}>
                                <Typography textAlign="center">
                                    Acheter crypto
                                </Typography>
                            </MenuItem>

                            <MenuItem onClick={() => {handleCloseTradeMenu(); navigate('/trade/sell/')}}>
                                <Typography textAlign="center">
                                    Vendre crypto
                                </Typography>
                            </MenuItem>

                            <MenuItem onClick={() => {handleCloseTradeMenu(); navigate('/swap/')}}>
                                <Typography textAlign="center">
                                    Swap
                                </Typography>
                            </MenuItem>

                        </Menu>

                        <Link to="/wallet/" className='link'>
                            <Button
                                sx={{ my: 2, color: 'white', marginLeft:'10px' }}
                                color="primary"
                                startIcon={<AccountBalanceWalletIcon />}
                            >
                                Wallet
                            </Button>
                        </Link>

                        <div style={{
                                        marginLeft: '20px',
                                        marginRight: '20px'
                                    }}>
                            <Badge badgeContent='Nouveau' color='error'>
                                <Link to="/cft-token/" className='link text-white' style={{paddingTop:'5px'}}>
                                    Token { cftTokenInfo.symbol }
                                </Link>
                            </Badge>
                        </div>

                        <a
                            href="https://wa.me/+237650839431"
                            style={{ color: 'white', marginLeft:'30px' }}
                            target={'_blank'}
                            className="link"
                        >
                            +237 650 839 431 <br />
                            WhatsApp
                        </a>

                        <Link to='/news/' className='link'>
                            <Button
                                sx={{ my: 2, color: 'white', marginLeft:'10px' }}
                                color="primary"
                            >
                                News
                            </Button>
                        </Link>
                        
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>

                        <Tooltip title="Afficher plus d'options">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={authUser.lastName || authUser.firstName ? authUser.firstName + " " + authUser.lastName : "Idriss"} 
                                        src={authUser.avatarUrl} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >

                            <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/')}}>
                                <Typography textAlign="center">Accueil</Typography>
                            </MenuItem>

                            {
                                !basicDatas.isLoggedIn

                                ?

                                    <div>
                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/auth/login/')}}>
                                            <Typography textAlign="center">Se connecter</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/auth/signup/')}}>
                                            <Typography textAlign="center">Créer un compte</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/auth/reset-password/')}}>
                                            <Typography textAlign="center">Mot de passe oublié</Typography>
                                        </MenuItem>
                                    </div>
                                :

                                    <div>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/account/')}}>
                                            <Typography textAlign="center">Mon compte</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/kyc/')}}>
                                            <Typography textAlign="center">Vérification</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/orders/')}}>
                                            <Typography textAlign="center">Mes ordres</Typography>
                                        </MenuItem>

                                        {
                                            authUser.isCftPartner && (authUser.isCftPartner.status === 'certified') &&
                                            <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/pro-dashboard/')}}>
                                                <Typography textAlign="center">Compte professionnel</Typography>
                                            </MenuItem>
                                        }

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/sponsorships/')}}>
                                            <Typography textAlign="center">Parrainages</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/rewards-center/')}}>
                                            <Typography textAlign="center">Centre de récompenses</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/liquidity-provider/')}}>
                                            <Typography textAlign="center">Fournisseur de liquidité</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => {handleCloseUserMenu(); navigate('/settings/')}}>
                                            <Typography textAlign="center">Paramètres</Typography>
                                        </MenuItem>

                                        <MenuItem onClick={() => basicDatas.logOut()}>
                                            <Typography textAlign="center">Se déconnecter</Typography>
                                        </MenuItem>
                                    </div>

                            }

                        </Menu>
                    </Box>

                </Toolbar>

            </Container>

        </AppBar>
    );
};
export default Header;
