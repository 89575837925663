import { useEffect } from 'react';
import { 
    Button, Box, Typography, TextField, MenuItem,
    FormControl, InputLabel, Select, Chip, Checkbox
} from '@mui/material';
import QueryString from 'qs';
import { useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { basicProps, networkType } from '../../../utils/interfaces';
import { useParams } from 'react-router-dom';

export default function ItemNetwork(props: basicProps) {

    const basicDatas = props.basicDatas;
    const { networkID } = useParams();

    const [network, setNetwork] = useState<networkType>();
    const [sw, setSw] = useState('')

    useEffect(() => {
        getNetworkInfo();
    }, [])

    const getNetworkInfo = () => {
        axiosApi.post("ctr/networks/get-network-infos.php", QueryString.stringify({ networkID }))
        .then(response => {

            if(response.data.status === 'success') {
                setNetwork(response.data.network);
            }
        })
    }

    const setNetworkOnServer = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if(!network) return;
    
        let data = new FormData(e.currentTarget);
        
        data.append('id', '' + network.id);
        data.append('loaderText', 'Mise à jour en cours...');
    
        axiosApi.post('ctr/networks/set-network.php', data)

    }

    const allowNetworkManager = (actionType:string) => {
        axiosApi.post('ctr/networks/allow-buy-sell-action.php', QueryString.stringify({
            actionType, 
            network: network?.symbol,
            sw
        })).then(response => {
            if(response.data.status === 'success'){
                getNetworkInfo();
            }
        })
    }

    const congestedNetworkManager = () => {
        axiosApi.post('ctr/networks/set-network-state.php', QueryString.stringify({
            network: network?.symbol,
            sw
        })).then(response => {
            if(response.data.status === 'success'){
                getNetworkInfo();
            }
        })
    }

    if(!network) return (
        <Typography sx={{
            textAlign: 'center',
            mt: 5
        }}>
            Chargement...
        </Typography>
    )

    return (
            
        <Box>

            <Typography sx={{textAlign: 'center', mt: 5, mb: 5}}>

                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    { network.symbol }
                </Typography>

                <Typography variant='h6'>
                    { network.name }
                </Typography>

            </Typography>

            <Box
                component="form"   
                id="form_add_network"
                sx={{
                    bgcolor: 'ButtonFace',
                    mt: 4,
                    mb: 6,
                    p: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: 5
                }}
                noValidate
                autoComplete="off"
                onSubmit={setNetworkOnServer}
                >

                <TextField
                    required
                    name='name'
                    id="outlined-required"
                    label="Nom devise"
                    defaultValue={network.name}
                    />

                <TextField
                    required
                    name="symbol"
                    id="outlined-disabled"
                    label="Symbole devise"
                    defaultValue={network.symbol}
                />

                <TextField
                    required
                    name="binanceSymbol"
                    id="outlined-disabled"
                    label="Binance Symbol"
                    defaultValue={network.binanceSymbol}
                />

                <TextField
                    required
                    name='address'
                    label="Adresse du portefeuille"
                    placeholder='Adresse'
                    defaultValue={ network.address }
                    />

                <TextField
                    required
                    type='file'
                    name='qrcode'
                    label="QRcode"
                    autoFocus
                    />

                <TextField
                    name='memo'
                    id="memo"
                    label="Memo"
                    placeholder='memo'
                    defaultValue={ network.memo }
                    />

                <FormControl 
                    fullWidth >

                    <InputLabel id="memo-required-for-buy">Memo requis achat</InputLabel>
                
                    <Select
                        labelId="memo-required-for-buy"
                        name='memoRequiredForBuy'
                        label="Memo requis achat"
                        defaultValue={network.memoRequiredForBuy}
                        fullWidth
                        margin="dense"
                        required
                        >

                        {
                            [0, 1].map((type, index) => {

                                return (
                                    <MenuItem key={ index } value={ type }>
                                        { type }
                                    </MenuItem>
                                )

                            })
                        }
        
                    </Select>        

                </FormControl>

                <FormControl 
                    fullWidth >

                    <InputLabel id="memo-required-for-sell">Memo requis vente</InputLabel>
                
                    <Select
                        labelId="memo-required-for-sell"
                        name='memoRequiredForSell'
                        label="Memo requis vente"
                        defaultValue={network.memoRequiredForSell}
                        fullWidth
                        margin="dense"
                        required
                        >

                        {
                            [0, 1].map((type, index) => {

                                return (
                                    <MenuItem key={ index } value={ type }>
                                        { type }
                                    </MenuItem>
                                )

                            })
                        }
        
                    </Select>        

                </FormControl>

                <TextField
                    required
                    name='networkCompatibility'
                    id="networkCompatibility"
                    label="Compatibilité réseau"
                    defaultValue={ network.networkCompatibility }
                    />

                <TextField
                    type='number'
                    inputProps={{ step: 'any'}}
                    name='maxTimePaymentLimit'
                    label="Temps d'attente de paiement max (minutes)"
                    defaultValue={ network.maxTimePaymentLimit }
                />

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    value={sw}
                    onChange={(e) => setSw(e.target.value)}
                />

                <Button type='submit' variant='contained' sx={{p:1.9}}>
                    Modifier
                </Button>

            </Box>


            <Typography sx={{mt:5, mb:2}}>
                Activer/Désactiver pour toutes les cryptos
            </Typography>

            <Chip
                label="Activer pour l'achat"
                onClick={() => {}}
                avatar={<Checkbox onChange={() => allowNetworkManager('buy')}
                            checked={network.allowForBuy == 1}
                            />
                        }
                variant="outlined"
                sx={{m:1}}
            />

            <Chip
                label="Activer pour la vente"
                onClick={() => {}}
                avatar={<Checkbox onChange={() => allowNetworkManager('sell')}
                            checked={network.allowForSell == 1}
                            />
                        }
                variant="outlined"
                sx={{m:1}}
            />


            <Typography sx={{mt:5, mb:2}}>
                État du réseau
            </Typography>

            <Chip
                label="Congestionné"
                onClick={() => {}}
                avatar={<Checkbox onChange={congestedNetworkManager}
                            checked={network.congested == 1}
                            />
                        }
                variant="outlined"
                sx={{m:1}}
            />

        </Box>
    )
}
