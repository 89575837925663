import { useState } from 'react'
import { basicProps, orderType } from '../../../utils/interfaces'
import { orderAttachedMessagesType } from './Orders'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import InfoPopup from '../../../components/InfoPopup';
import { copyClipboard, readClipboard } from '../../../functions/basicFunctions';
import { toast } from 'react-toastify';

interface myProps extends basicProps {
    orderData: orderType,
    orderAttachedMessages: orderAttachedMessagesType[]
}

export default function CheckHash(props: myProps) {
    const orderData = props.orderData;

    const [open, setOpen] = useState(false);
    const [txID, setTxID] = useState('');
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const readTxIdClipboard = () => {

        readClipboard((clipboardContent) => {
            if(clipboardContent && typeof clipboardContent === 'string') setTxID(clipboardContent)
        })
    }

    const check = () => {
        if(orderData.basic.user_txid?.toLowerCase().replaceAll(' ', '') == txID.toLowerCase()) {
            toast.success("Le hash est correct !");
        }

        else {
            toast.warn("Le hash n'est pas correct !")
        }
    }


    return (
        <>

            <Button type='button' color='warning' onClick={() => setOpen(true)}>
                Vérifier hash
            </Button>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Vérifier le hash</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>

                    <Typography 
                        sx={{
                            mb: 2,
                            textAlign: 'center'
                        }}
                    >
                        <Typography variant='caption'>
                            Afin de confirmer que le hash associé à cet ordre est bien conforme à celui de la transaction, veuillez comparer.
                        </Typography>

                    </Typography>
                    
                    <Typography 
                        variant='body1' 
                        title="Cliquez pour copier"
                        onClick={() => copyClipboard(orderData.basic.user_txid + '', "Hash de l'utilisateur copié")}
                        sx={{ 
                            wordWrap: "break-word",
                            p: 2,
                            mb: 3,
                            border: '1px solid #ddd',
                            borderRadius: 1,
                            cursor: 'pointer'
                        }}
                    >
                        { orderData.basic.user_txid }
                    </Typography>
                    
                        
                    <Box>
                        
                        <Typography sx={{mb: 1}}>
                            <label htmlFor="txID" className="small">
                                Hash de transaction
                            </label> 

                            {
                                txID.length === 0 &&

                                <Button color='info' 
                                        style={{float: 'right'}}
                                        onClick={readTxIdClipboard} >
                                    Coller
                                </Button>
                            }

                        </Typography>

                        <TextareaAutosize
                            aria-label="Tx ID"
                            name='txID'
                            id="txID"
                            minRows={3}
                            required
                            value={txID}
                            onChange={(e) => setTxID(e.target.value)}
                            placeholder="Entrez l'ID de transaction"
                            style={{ 
                                width:'100%', 
                                padding:'10px', 
                                borderRadius:'5px', 
                                border: "1px solid #bbb"
                            }}
                        />

                        <Button 
                            type='button' 
                            variant='contained'
                            fullWidth
                            onClick={check}
                            sx={{
                                mt: 2
                            }}
                        >
                            Vérifier
                        </Button>

                    </Box> 

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
        </>
        
    )
}
